import React, { useEffect, useState } from "react";
import {
  Box,
  Avatar,
  Typography,
  IconButton,
  useMediaQuery,
  Menu,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Person2Icon from "@mui/icons-material/Person2";
import LogoutIcon from "@mui/icons-material/Logout";
import { MdNotificationsNone, MdHelpOutline } from "react-icons/md";
import { useAtom } from "jotai";
import {
  anchorElAtom,
  isLoaderActiveAtom,
  breadcrumbsAtom, storage,
  userAtom,
} from "../../atoms";
import { ProfileMenu } from "./ProfileMenu";
import { LoginRoute, UserProfileRoute } from "../../routes";
import { LogoffRequest } from "../../types";
import { SESSION_STORAGE_USER_KEY } from "../../constants";
import { useNavigate } from "react-router-dom";
import { useLogoffMutation } from "../../api";
import { Breadcrumbs } from "../common";

interface TopbarProps {
  userName: string;
}

export const Topbar: React.FC<TopbarProps> = ({ userName }) => {
  const isMobile = useMediaQuery("(max-width:700px)");
  const [anchorEl, setAnchorEl] = useAtom(anchorElAtom);
  const [, setIsLoaderActive] = useAtom(isLoaderActiveAtom);
  const [breadcrumbs] = useAtom(breadcrumbsAtom);

  useEffect(() => {
    if (!isMobile) {
      setAnchorEl(null);
    }
  }, [isMobile]);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const { mutate, isSuccess, isError, data, error, isLoading } =
    useLogoffMutation();
  const [user, setUser] = useAtom(userAtom);

  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess) {
      setIsLoaderActive(false);
      setUser(null);
      storage.removeItem(SESSION_STORAGE_USER_KEY);
      navigate(LoginRoute.path);
    }
    if (isError) {
      setIsLoaderActive(false);
    }
    if (isLoading) {
      setIsLoaderActive(true);
    }
  }, [isSuccess, isError, data, error, isLoading]);

  const handleLogoff = async ({ userName }: LogoffRequest) => {
    setAnchorEl(null);
    mutate({ userName });
  };

  return (
    <Box
      sx={{
        height: "75px",
        display: "flex",
        boxSizing: "border-box",
        background: "#FFFFFF",
        border: "1px solid #F0F2FE",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "space-between",
      }}
    >
      <Box sx={{ px: 3 }}>
        <Breadcrumbs
          items={breadcrumbs}
        />
      </Box>
      {isMobile ? (
        <>
          <IconButton
            onClick={handleMenuOpen}
            style={{ flexDirection: "row-reverse" }}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            style={{ flexDirection: "row-reverse" }}
          >
            <MenuItem
              sx={{
                display: "flex",
                gap: 1,
              }}
              onClick={handleMenuClose}
            >
              <MdHelpOutline
                style={{
                  width: 21,
                  height: 21,
                }}
              />
              Help
            </MenuItem>
            <MenuItem
              sx={{
                display: "flex",
                gap: 1,
              }}
              onClick={handleMenuClose}
            >
              <MdNotificationsNone
                style={{
                  width: 21,
                  height: 21,
                }}
              />
              Notifications
            </MenuItem>
            <MenuItem
              sx={{
                display: "flex",
                gap: 1,
              }}
              onClick={() => {
                navigate(UserProfileRoute.path);
                handleMenuClose();
              }}
            >
              <Person2Icon
                style={{
                  width: 21,
                  height: 21,
                }}
              />
              Profile
            </MenuItem>
            <MenuItem
              sx={{
                display: "flex",
                gap: 1,
              }}
              onClick={() => {
                if (user) handleLogoff({ userName: user.userName });
              }}
            >
              <LogoutIcon
                style={{
                  width: 21,
                  height: 21,
                }}
              />
              Logout
            </MenuItem>
            <MenuItem
              onClick={handleMenuClose}
              sx={{
                display: "flex",
                gap: 1,
              }}
            >
              <Avatar
                alt={userName}
                src={user?.profilePic}
                sx={{
                  width: 21,
                  height: 21,
                }}
              />
              <Typography sx={{ textTransform: "capitalize" }}>
                {userName}
              </Typography>
            </MenuItem>
          </Menu>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
            }}
          >
            <MdHelpOutline
              style={{
                width: 21,
                height: 21,
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
            }}
          >
            <MdNotificationsNone
              style={{
                width: 21,
                height: 21,
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mr: 3,
            }}
          >
            <ProfileMenu />
          </Box>
        </Box>
      )}
    </Box>
  );
};
