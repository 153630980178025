import { Box, Grid2 as Grid } from "@mui/material";
import { OrganizationAccordion } from "./OrganizationAccordion";
import {
  isCreateOrganizationModeAtom,
  selectedOrgIdAtom,
  userAtom,
} from "../../atoms";
import { useAtom } from "jotai";
import { useLocation, useParams } from "react-router-dom";
import { useEffect } from "react";
import { SadmCreateOrganizationRoute } from "../../routes";
import { Role } from "../../enums";
import { organizationMenus } from "./OrganizationMenus";

export const OrganizationDetail = () => {
  const params = useParams();
  const location = useLocation();
  const [user] = useAtom(userAtom);
  const [, setSelectedOrgId] = useAtom(selectedOrgIdAtom);
  const [, setIsCreateOrganizationMode] = useAtom(isCreateOrganizationModeAtom);

  useEffect(() => {
    if (
      location.pathname.split("/").pop() === SadmCreateOrganizationRoute.path
    ) {
      setIsCreateOrganizationMode(true);
    } else if (params.orgId && user && user.roleCode !== Role.Customer) {
      setSelectedOrgId(Number(params.orgId));
    }
  }, [params]);

  return (
    <Box
      sx={{
        minHeight: "715px",
        display: "flex",
        width: "100%",
        flexGrow: 1,
      }}
    >
      <Grid
        container
        direction={"column"}
        spacing={2}
        gap={0}
        sx={{
          width: "100%",
          height: "100%",
          borderRadius: "10px",
          direction: "row",
          py: "1rem",
        }}
      >
        {organizationMenus.map((menu, index) => (
          <OrganizationAccordion key={index} menu={menu} />
        ))}
      </Grid>
    </Box>
  );
};
