import { Box, Divider, Skeleton, Tooltip, Typography } from "@mui/material";
import { Grid2 as Grid } from "@mui/material";
import { AccountProfileInfo } from "../../../../../types";
import { EditIcon } from "../../../../icons";

type ViewAccountProfileBodyProps = {
  data?: AccountProfileInfo;
  isLoading?: boolean;
  handleEditMode: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ViewAccountProfileBody = ({
  data,
  isLoading,
  handleEditMode,
}: ViewAccountProfileBodyProps) => {
  return (
    <>
      <Grid
        container
        spacing={{ xs: 0, sm: 2 }}
        sx={{
          width: "100%",
          height: "100%",
          px: 3,
          position: "relative",
        }}
      >
        <Box
          onClick={() => handleEditMode(true)}
          sx={{
            ":hover": {
              cursor: "pointer",
            },
            position: "absolute",
            right: "0.5rem",
            top: "0.5rem",
          }}
        >
          {data && <EditIcon width={"21px"} active={true} />}
        </Box>
        <Grid container size={{ xs: 12 }} sx={{ px: 4 }}>
          <Tooltip
            placement="top-start"
            title={
              "This is the primary Billing Location and not the working location address"
            }
          >
            <Typography sx={{ pt: 1.5, textAlign: "left" }}>
              {"Billing Address"}
            </Typography>
          </Tooltip>
          <Divider sx={{ width: "100%" }} />
        </Grid>
        <Grid
          container
          size={{ xs: 12, sm: 6 }}
          gap={3}
          sx={{
            px: { xs: 0, sm: 4 },
            pt: 3,
            pb: { xs: 0, sm: 3 },
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {isLoading ? (
              <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
            ) : (
              <>
                <Typography
                  sx={{
                    color: "#606060",
                    textAlign: "left",
                    fontSize: "14px",
                  }}
                >
                  Contact Person
                </Typography>
                <Typography
                  sx={{
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  {data && data.contactPerson ? data.contactPerson : "N/A"}
                </Typography>
              </>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {isLoading ? (
              <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
            ) : (
              <>
                <Typography
                  sx={{
                    color: "#606060",
                    textAlign: "left",
                    fontSize: "14px",
                  }}
                >
                  Email
                </Typography>
                <Typography
                  sx={{
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  {data && data.email ? data.email : "N/A"}
                </Typography>
              </>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {isLoading ? (
              <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
            ) : (
              <>
                <Typography
                  sx={{
                    color: "#606060",
                    textAlign: "left",
                    fontSize: "14px",
                  }}
                >
                  Contact Number
                </Typography>
                <Typography
                  sx={{
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  {data && data.contactNumber ? data.contactNumber : "N/A"}
                </Typography>
              </>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {isLoading ? (
              <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
            ) : (
              <>
                <Typography
                  sx={{
                    color: "#606060",
                    textAlign: "left",
                    fontSize: "14px",
                  }}
                >
                  Website
                </Typography>
                <Typography
                  sx={{
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  {data && data.website ? data.website : "N/A"}
                </Typography>
              </>
            )}
          </Box>
        </Grid>
        <Grid
          container
          size={{ xs: 12, sm: 6 }}
          gap={3}
          sx={{
            px: { xs: 0, sm: 4 },
            py: 4,
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {isLoading ? (
              <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
            ) : (
              <>
                <Typography
                  sx={{
                    color: "#606060",
                    textAlign: "left",
                    fontSize: "14px",
                  }}
                >
                  Address Line 1
                </Typography>
                <Typography
                  sx={{
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  {data && data.addressLine1 ? data.addressLine1 : "N/A"}
                </Typography>
              </>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {isLoading ? (
              <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
            ) : (
              <>
                <Typography
                  sx={{
                    color: "#606060",
                    textAlign: "left",
                    fontSize: "14px",
                  }}
                >
                  Address Line 2
                </Typography>
                <Typography
                  sx={{
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  {data && data.addressLine2 ? data.addressLine2 : "N/A"}
                </Typography>
              </>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {isLoading ? (
              <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
            ) : (
              <>
                <Typography
                  sx={{
                    color: "#606060",
                    textAlign: "left",
                    fontSize: "14px",
                  }}
                >
                  City, State, ZIP
                </Typography>
                <Typography
                  sx={{
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  {data && data.city && data.state && data.zip
                    ? data.city + ", " + data.state + ", " + data.zip
                    : "N/A"}
                </Typography>
              </>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {isLoading ? (
              <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
            ) : (
              <>
                <Typography
                  sx={{
                    color: "#606060",
                    textAlign: "left",
                    fontSize: "14px",
                  }}
                >
                  Instructions
                </Typography>
                <Typography
                  sx={{
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  {data && data.instruction ? data.instruction : "N/A"}
                </Typography>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
