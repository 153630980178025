import { useEffect, useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import {
  fleetFilterStateAtom,
  fleetsDataGridPaginationAtom,
  selectedAccountAtom,
  selectedAccountIdAtom,
  selectedOrgIdAtom,
} from "../../atoms";
import { useAtom } from "jotai";
import {
  useFetchAccountFleetsQuery,
  useUpdateFleetMutation,
  useAddFleetMutation,
  useDeleteFleetMutation,
} from "../../api";
import { LocationFleet, FleetInfo, TabItem, ToastOptions } from "../../types";
import { Backdrop, Box, Checkbox, Grid2, Typography } from "@mui/material";
import { Tabs as LocationTabs, Toast } from "../common";
import { FleetToolbar } from "../toolbar";
import { DataGrid } from "../common";
import { LocationModel } from "../../models/LocationModel";
import {
  cleanString,
  convertToCSV,
  exportBlob,
  searchObjects,
  stringComparator,
  theme,
} from "../../utils";
import { ActionIcons } from "../common/data-grid";
import { AddOrEditFleetModal } from "../common/modal";

export const Fleet = () => {
  const [selectedAccount] = useAtom(selectedAccountAtom);
  const [locationsTabItems, setLocationsTabItems] = useState<TabItem[]>([]);
  const [currentAccountId] = useAtom(selectedAccountIdAtom);
  const [accountFleets, setAccountFleets] = useState<LocationFleet[]>([]);
  const [selectedOrgId] = useAtom(selectedOrgIdAtom);
  const [selectedLocation, setSelectedLocation] = useState<number>(0);
  const fetchAccountFleetsQuery = useFetchAccountFleetsQuery(
    selectedOrgId,
    currentAccountId || 0,
    selectedOrgId && currentAccountId ? true : false
  );
  const [fleetList, setFleetList] = useState<FleetInfo[]>([]);
  const [searchItem, setSearchItem] = useState("");
  const [filteredFleetList, setFilteredFleetList] = useState<FleetInfo[]>([]);
  const [fleetsPaginationModel, setFleetsPaginationModel] = useAtom(
    fleetsDataGridPaginationAtom
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("Add Fleet");
  const [selectedFleet, setSelectedFleet] = useState<FleetInfo>();
  const updateFleetMutation = useUpdateFleetMutation();
  const addFleetMutation = useAddFleetMutation();
  const deleteFleetMutation = useDeleteFleetMutation();
  const [toastOptions, setToastOptions] = useState<ToastOptions>({
    open: false,
    message: "",
    severity: "info",
  });
  const [selectedFilters, setSelectedFilters] = useAtom(fleetFilterStateAtom);
  const [selectedFleetIds, setSelectedFleetIds] = useState<number[]>([]);

  const columns: GridColDef[] = [
    {
      field: "washTypeName",
      headerName: "Wash Type",
      width: 200,
      editable: false,
      sortComparator: stringComparator,
    },
    {
      field: "vehicleNumber",
      headerName: "Vehicle Number",
      width: 200,
      editable: false,
    },
    {
      field: "isPreSelected",
      headerName: "Pre-Selected",
      width: 200,
      editable: false,
      renderCell: (params) => (
        <Checkbox
          checked={params.value}
          onChange={(e) => {
            params.value = e.target.checked;
          }}
          sx={{
            "& .MuiSvgIcon-root": {
              fontSize: 16,
            },
          }}
        />
      ),
    },
    {
      field: "Actions",
      headerName: "Actions",
      width: 200,
      editable: false,
      renderCell: (params) => (
        <ActionIcons
          confirmationModalTitle="Delete Fleet"
          confirmationModalMessage="Are you sure you want to delete this fleet?"
          onEdit={() => handleEdit(params.row)}
          onDelete={() => handleDelete(params.row)}
          rowData={params.row}
        />
      ),
    },
  ];

  const handleEdit = (rowData: FleetInfo) => {
    setSelectedFleet(rowData);
    setModalTitle("Edit Fleet");
    setIsModalOpen(true);
  };

  const handleAddButtonClick = () => {
    setSelectedFleet(undefined);
    setModalTitle("Add Fleet");
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const generateDeleteFleetPayload = (fleetIds: number[]) => {
    return {
      accountId: currentAccountId || 0,
      orgId: selectedOrgId,
      fleetIds: fleetIds,
    };
  };
  const generateFleetPayload = (
    fleet: FleetInfo,
    selectedLocation: number,
    currentAccountId: number,
    orgId: number
  ) => {
    return {
      locationNumber: selectedLocation,
      washTypeId: fleet.washTypeId,
      vehicleNumber: fleet.vehicleNumber,
      isPreSelected: fleet.isPreSelected,
      accountId: currentAccountId,
      orgId: orgId,
      accountFleetId: fleet.accountFleetId || 0,
    };
  };

  const handleSaveFleet = (fleet: FleetInfo | undefined) => {
    setIsModalOpen(false);
    if (fleet !== undefined) {
      const payload = generateFleetPayload(
        fleet,
        selectedLocation,
        currentAccountId || 0,
        selectedOrgId
      );

      if (!fleet.accountFleetId) {
        addFleetMutation.mutate(payload);
      } else {
        updateFleetMutation.mutate(payload);
      }
    }
  };

  const handleDelete = (rowData: any) => {
    if (rowData.accountFleetId)
      deleteFleetMutation.mutate(
        generateDeleteFleetPayload([rowData.accountFleetId])
      );
  };

  const resetPage = () => {
    setFleetsPaginationModel({ ...fleetsPaginationModel, page: 0 });
  };

  const handleRefresh = () => {
    fetchAccountFleetsQuery.refetch();
  };

  const handleRemoveButtonClick = () => {
    deleteFleetMutation.mutate(generateDeleteFleetPayload(selectedFleetIds));
  };

  const getFilteredFleetList = () => {
    const { isPreSelected, ...restFilters } = selectedFilters;
    let filters = restFilters;
    if (isPreSelected !== "all") {
      const isPreSelectedBoolean = isPreSelected === "true";
      filters = { isPreSelected: isPreSelectedBoolean, ...restFilters };
    }
    const filteredList = fleetList.filter((fleet) => {
      return Object.keys(filters).every((key) => {
        const fleetValue = (fleet as any)[key];
        const filterValue = filters[key];
        return fleetValue === filterValue;
      });
    });
    return filteredList;
  };

  const handleApplyFilters = () => {
    setFilteredFleetList(getFilteredAndSearchedFleets());
  };

  const handleRowSelectionChange = (selectedRowIds: number[]) => {
    setSelectedFleetIds(selectedRowIds);
  };

  const getFilteredAndSearchedFleets = () => {
    const filtered = searchObjects(
      getFilteredFleetList(),
      ["id", "accountFleetId", "washTypeId", "isPreSelected", "Actions"],
      searchItem
    );
    return filtered;
  };

  const handleSearch = (item: string) => {
    setSearchItem(item);
  };

  const handleDownload = () => {
    const csvData = convertToCSV(columns, filteredFleetList, ["Actions"]);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const location = locationsTabItems.find(
      (item) => item.value === selectedLocation
    );
    const accountName = selectedAccount ? selectedAccount.accountName : "";
    const locationName = location ? location.key : "";
    const filename =
      cleanString(accountName) +
      "_" +
      cleanString(locationName) +
      "_fleets.csv";
    exportBlob(blob, filename);
    setToastOptions({
      open: true,
      message: "Fleet exported successfully!",
      severity: "success",
    });
  };

  useEffect(() => {
    if (!updateFleetMutation.isLoading) {
      if (updateFleetMutation.isSuccess) {
        fetchAccountFleetsQuery.refetch();
        setToastOptions({
          open: true,
          message: "Fleet updated successfully",
          severity: "success",
        });
      } else if (updateFleetMutation.isError) {
        const { status } = updateFleetMutation.error;

        switch (status) {
          case 400:
            setToastOptions({
              open: true,
              message: "Fleet does not exist.",
              severity: "error",
            });
            break;

          case 500:
            setToastOptions({
              open: true,
              message: "AccountFleet already exists.",
              severity: "error",
            });
            break;

          default:
            setToastOptions({
              open: true,
              message: "Something went wrong.",
              severity: "error",
            });
            break;
        }
      }
    }
  }, [
    updateFleetMutation.isLoading,
    updateFleetMutation.isSuccess,
    updateFleetMutation.isError,
    fetchAccountFleetsQuery.refetch,
  ]);

  useEffect(() => {
    if (!addFleetMutation.isLoading) {
      if (addFleetMutation.isSuccess) {
        fetchAccountFleetsQuery.refetch();
        setToastOptions({
          open: true,
          message: "Fleet added successfully",
          severity: "success",
        });
      } else if (addFleetMutation.isError) {
        setToastOptions({
          open: true,
          message: "Failed to add fleet",
          severity: "error",
        });
      }
    }
  }, [
    addFleetMutation.isLoading,
    addFleetMutation.isSuccess,
    addFleetMutation.isError,
    fetchAccountFleetsQuery.refetch,
  ]);

  useEffect(() => {
    setSelectedFilters({});
  }, [selectedLocation]);

  useEffect(() => {
    if (accountFleets.length > 0) {
      const filteredAccountFleetList: LocationFleet[] = accountFleets.filter(
        (accountFleet) => accountFleet.location.number === selectedLocation
      );
      if (filteredAccountFleetList.length > 0) {
        setFleetList(filteredAccountFleetList[0]?.fleets);
        setFilteredFleetList(filteredAccountFleetList[0]?.fleets);
      } else {
        setFleetList([]);
        setFilteredFleetList([]);
      }
    }
  }, [selectedLocation, accountFleets]);

  useEffect(() => {
    if (accountFleets.length > 0) {
      if (Object.keys(selectedFilters).length > 0)
        setFilteredFleetList(getFilteredFleetList());
    }
  }, [fleetList]);

  useEffect(() => {
    if (
      fetchAccountFleetsQuery.isSuccess &&
      fetchAccountFleetsQuery.data.length > 0
    ) {
      const accountLocationFleets = LocationModel.buildLocationFleets(
        fetchAccountFleetsQuery.data
      );
      const locationTabItems = LocationModel.buildLocationsTab(
        fetchAccountFleetsQuery.data
      );

      setAccountFleets(accountLocationFleets);
      setLocationsTabItems(locationTabItems);
      setSelectedLocation(
        selectedLocation
          ? selectedLocation
          : accountLocationFleets[0].location.number
      );
    } else if (
      fetchAccountFleetsQuery.isError ||
      fetchAccountFleetsQuery.isLoading
    ) {
      setAccountFleets([]);
      setFleetList([]);
    }
  }, [
    fetchAccountFleetsQuery.isSuccess,
    fetchAccountFleetsQuery.isError,
    fetchAccountFleetsQuery.data,
    fetchAccountFleetsQuery.isLoading,
    fetchAccountFleetsQuery.isRefetching,
  ]);

  useEffect(() => {
    if (!deleteFleetMutation.isLoading) {
      if (deleteFleetMutation.isSuccess) {
        fetchAccountFleetsQuery.refetch();
        setToastOptions({
          open: true,
          message: "Fleet deleted successfully",
          severity: "success",
        });
      } else if (deleteFleetMutation.isError) {
        setToastOptions({
          open: true,
          message: "Failed to delete fleet",
          severity: "error",
        });
      }
    }
  }, [
    deleteFleetMutation.isLoading,
    deleteFleetMutation.isSuccess,
    deleteFleetMutation.isError,
    fetchAccountFleetsQuery.refetch,
  ]);

  useEffect(() => {
    setFilteredFleetList(getFilteredAndSearchedFleets());
  }, [searchItem]);

  useEffect(() => {
    resetPage();
  }, [filteredFleetList]);

  return (
    <Box
      sx={{
        width: "100%",
        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "10px",
        position: "relative",
      }}
    >
      {!fetchAccountFleetsQuery.isLoading &&
        fetchAccountFleetsQuery.isSuccess &&
        locationsTabItems.length === 0 && (
          <Backdrop
            sx={{
              color: "#000",
              zIndex: 1000,
              position: "absolute",
              opacity: 0,
              backgroundImage:
                "linear-gradient(to right, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0.5))",
              backgroundColor: "rgba(0, 0, 0, 0)",
            }}
            open={true}
          >
            <Typography sx={{ p: 2, fontSize: { xs: "15px", sm: "18px" } }}>
              Please add a location first
            </Typography>
          </Backdrop>
        )}

      <Toast setOptions={setToastOptions} options={toastOptions} />

      <Box>
        <Grid2
          container
          direction={"column"}
          spacing={2}
          sx={{
            backgroundColor: theme.palette.secondary.light,
            padding: "20px",
            paddingTop: "0px",
            paddingBottom: "0px",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            borderBottom: "1px solid #E5E5E5",
          }}
        >
          <LocationTabs
            defaultTab="No Location"
            isLoading={
              fetchAccountFleetsQuery.isLoading ||
              fetchAccountFleetsQuery.isRefetching
            }
            tabItems={locationsTabItems}
            onTabChange={setSelectedLocation}
            value={selectedLocation}
          />
        </Grid2>
        <Grid2
          container
          direction={"column"}
          spacing={2}
          sx={{
            marginTop: "10px",
            marginBottom: "10px",
            backgroundColor: "#f5f5f5",
          }}
        >
          <FleetToolbar
            onRefresh={handleRefresh}
            onDownload={handleDownload}
            onSearch={handleSearch}
            onApplyFilters={handleApplyFilters}
            locationNumber={selectedLocation}
            disableDownload={accountFleets.length === 0}
            disableRefresh={accountFleets.length === 0}
            disableUpload={accountFleets.length === 0}
          />
        </Grid2>
        <Grid2 container direction={"column"} spacing={2}>
          <DataGrid
            columns={columns}
            rows={filteredFleetList}
            loading={
              fetchAccountFleetsQuery.isLoading ||
              addFleetMutation.isLoading ||
              updateFleetMutation.isLoading ||
              deleteFleetMutation.isLoading ||
              fetchAccountFleetsQuery.isRefetching
            }
            initialState={{
              sorting: {
                sortModel: [{ field: "washTypeName", sort: "asc" }],
              },
            }}
            paginationModel={fleetsPaginationModel}
            setPaginationModel={setFleetsPaginationModel}
            onSelectedRowIdsChange={handleRowSelectionChange}
            footerProps={{
              addBtnLabel: "Add",
              hasAddIcon: true,
              hasRemoveIcon: true,
              removeBtnLabel: "Delete",
              disableAddBtn: accountFleets.length === 0,
              onAddButtonClick: handleAddButtonClick,
              handleConfirmRemove: handleRemoveButtonClick,
              disableRemoveBtn: selectedFleetIds.length > 0 ? false : true,
              confirmationModalTitle: "Delete Fleet",
              confirmationModalMessage:
                selectedFleetIds.length == 1
                  ? "Are you sure you want to delete this fleet?"
                  : `Are you sure you want to delete these ${selectedFleetIds.length} fleets?`,
            }}
            sx={{
              border: "none",
              backgroundColor: theme.palette.secondary.light,
              padding: "20px",
              paddingTop: "0px",
              "& .MuiSvgIcon-root": {
                fontSize: 16,
              },
              borderBottomLeftRadius: "10px",
              borderBottomRightRadius: "10px",
              height: "70vh",
            }}
          />
        </Grid2>
      </Box>

      <AddOrEditFleetModal
        open={isModalOpen}
        title={modalTitle}
        primaryButtonText={"Save"}
        onClose={handleCloseModal}
        onSave={handleSaveFleet}
        fleet={selectedFleet}
      />
    </Box>
  );
};
