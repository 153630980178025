import { GridSlotsComponentsProps } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import { Grid2 as Grid, useMediaQuery } from "@mui/material";
import { Button } from "../../button";
import TablePagination from "@mui/material/TablePagination";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { ConfirmationModal } from "../../modal";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useState } from "react";
import { theme } from "../../../../utils";

export const DataGridFooter = ({
  addBtnLabel,
  removeBtnLabel,
  hasAddIcon,
  customAddIcon,
  hasRemoveIcon,
  customRemoveIcon,
  disableAddBtn = false,
  disableRemoveBtn = false,
  confirmationModalTitle = "Delete Item",
  confirmationModalMessage = "Are you sure you want to delete this item?",
  totalRecords = 0,
  paginationModel = { page: 0, pageSize: 5 },
  handleChangePage = () => {},
  handleChangeRowsPerPage = () => {},
  rowsPerPageOptions,
  onAddButtonClick,
  handleConfirmRemove = () => {},
}: NonNullable<GridSlotsComponentsProps["footer"]>) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleConfirm = () => {
    handleConfirmRemove();
    handleCloseModal();
  };

  return (
    <Grid
      container
      size={12}
      spacing={4}
      gap={1.4}
      sx={{
        display: "flex",
        flexDirection: { md: "row", xs: "column-reverse" },
        pb: 2,
        pt: 2,
      }}
    >
      <Grid
        size={{ xs: 12, md: 6 }}
        container
        spacing={2}
        direction={"row"}
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          justifyContent: { xs: "space-between", md: "flex-start" },
          p: 2,
        }}
      >
        {addBtnLabel && (
          <Grid size={{ lg: 4, xs: 6, sm: 5 }}>
            <Button
              label={addBtnLabel}
              variantType="primary"
              icon={
                hasAddIcon
                  ? customAddIcon
                    ? customAddIcon
                    : AddIcon
                  : undefined
              }
              sx={{
                padding: { xl: "1rem", lg: "0", xs: "0", sm: "0" },
                width: "100%",
                height: "30px",
              }}
              disabled={disableAddBtn}
              onClick={onAddButtonClick}
            />
          </Grid>
        )}
        {removeBtnLabel && (
          <Grid size={{ lg: 4, xs: 6, sm: 5 }}>
            <Button
              label={removeBtnLabel}
              variantType="secondary"
              icon={
                hasRemoveIcon
                  ? customRemoveIcon
                    ? customRemoveIcon
                    : DeleteOutlineIcon
                  : undefined
              }
              sx={{
                padding: "1rem",
                width: "100%",
                height: "30px",
              }}
              disabled={disableRemoveBtn}
              onClick={handleOpenModal}
            />
          </Grid>
        )}
      </Grid>
      <Grid
        container
        size={{ xs: 12, md: 6 }}
        sx={{
          alignItems: "center",
          justifyContent: "flex-end",
          flexGrow: 1,
        }}
        gap={0}
      >
        <Grid size={12}>
          <TablePagination
            sx={{
              display: "flex",
              justifyContent: { xs: "center", md: "flex-end" },
            }}
            component="div"
            count={totalRecords}
            page={paginationModel.page}
            rowsPerPage={paginationModel.pageSize}
            rowsPerPageOptions={isXs ? [] : rowsPerPageOptions}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            showFirstButton={true}
            showLastButton={true}
          />
        </Grid>
      </Grid>
      <ConfirmationModal
        open={isModalOpen}
        onClose={handleCloseModal}
        onConfirm={handleConfirm}
        icon={
          <DeleteOutlineOutlinedIcon
            fontSize="medium"
            sx={{ color: theme.palette.primary.main }}
          />
        }
        title={confirmationModalTitle}
        message={confirmationModalMessage}
      />
    </Grid>
  );
};
