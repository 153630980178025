import { useQuery } from "react-query";
import { config } from "../config";
import {
  SuperAdminDashboardResponse,
  Report,
  ReportRequestParams,
} from "../types";
import { client } from "./axios";
import { formatDate } from "../utils";

const buildReportsUrl = (
  pathTemplate: string,
  reportRequestParams: ReportRequestParams
): string => {
  const { orgId, accountId, filters } = reportRequestParams;

  const fromDate = formatDate(
    new Date(filters.fromDate || "").setHours(0, 0, 0),
    "YYYY-MM-DD HH:mm:ss"
  );
  const toDate = formatDate(
    new Date(filters.toDate || "").setHours(23, 59, 59),
    "YYYY-MM-DD HH:mm:ss"
  );

  if (pathTemplate === config.api.reportService.getWashDetailsReport) {
    let path =
      pathTemplate
        .replace("{orgId}", orgId.toString())
        .replace("{accountId}", accountId.toString()) +
      `?startDate=${fromDate}&endDate=${toDate}`;

    if (filters.locationNumber) {
      for (const locationNumber of filters.locationNumber) {
        path += `&locationNumbers=${locationNumber}`;
      }
    }

    return path;
  } else {
    const path = pathTemplate
      .replace("{orgId}", orgId.toString())
      .replace("{accountId}", accountId.toString())
      .replace("{startDate}", fromDate || "")
      .replace("{endDate}", toDate || "");

    return path;
  }
};

export const useFetchSuperAdminDashboardQuery = (
  orgId: number,
  cutOffDate?: string,
  fetchOnMount: boolean = true
) => {
  const fetchSuperAdminDashboard =
    async (): Promise<SuperAdminDashboardResponse> => {
      let path = config.api.reportService.getSuperAdminDashboard.replace(
        "{orgId}",
        orgId.toString()
      );
      if (cutOffDate) path += `?cutOffDate=` + cutOffDate;
      const response = await client.get<any>(path);
      return response.data.response;
    };

  return useQuery(
    ["fetchSuperAdminDashboard", orgId],
    fetchSuperAdminDashboard,
    {
      retry: false,
      enabled: fetchOnMount,
    }
  );
};

export const useFetchUserDashboardQuery = (
  orgId: number,
  accountId: number,
  cutOffDate?: string,
  fetchOnMount: boolean = true
) => {
  const fetchUserDashboard = async (): Promise<SuperAdminDashboardResponse> => {
    let path = config.api.reportService.getUserDashboard
      .replace("{orgId}", orgId.toString())
      .replace("{accountId}", accountId.toString());
    if (cutOffDate) path += `?cutOffDate=` + cutOffDate;
    const response = await client.get<any>(path);
    return response.data.response;
  };

  return useQuery(
    ["fetchUserDashboard", orgId, accountId],
    fetchUserDashboard,
    {
      retry: false,
      enabled: fetchOnMount,
    }
  );
};

export const useFetchAccountActivityReportQuery = (
  reportRequestParams: ReportRequestParams,
  fetchOnMount: boolean = true
) => {
  const fetchAccountActivityReport = async (): Promise<Report[]> => {
    const path = buildReportsUrl(
      config.api.reportService.getAccountActivityReport,
      reportRequestParams
    );
    const response = await client.get<any>(path);
    return response.data.response;
  };

  return useQuery(
    ["fetchAccountActivityReport", reportRequestParams],
    fetchAccountActivityReport,
    {
      retry: false,
      enabled: fetchOnMount,
    }
  );
};

export const useFetchWashDetailsReportQuery = (
  reportRequestParams: ReportRequestParams,
  fetchOnMount: boolean = true
) => {
  const fetchWashDetailsReport = async (): Promise<Report[]> => {
    const path = buildReportsUrl(
      config.api.reportService.getWashDetailsReport,
      reportRequestParams
    );
    const response = await client.get<any>(path);
    return response.data.response;
  };

  return useQuery(
    ["fetchWashDetailsReport", reportRequestParams],
    fetchWashDetailsReport,
    {
      retry: false,
      enabled: fetchOnMount,
    }
  );
};

export const useFetchDownloadWashDetailsReportQuery = (
  reportRequestParams: ReportRequestParams,
  fetchOnMount: boolean = true
) => {
  const fetchDownloadWashDetailsReport = async (): Promise<ArrayBuffer> => {
    const path = buildReportsUrl(
      config.api.reportService.getWashDetailsPdfReport,
      reportRequestParams
    );
    const response = await client.get<any>(path, {
      headers: {
        "Content-Type": "application/pdf",
        Accept: "application/pdf",
      },
      responseType: "arraybuffer",
      responseEncoding: "binary",
    });
    return response.data;
  };

  return useQuery(
    ["fetchDownloadWashDetailsReport", reportRequestParams],
    fetchDownloadWashDetailsReport,
    {
      retry: false,
      enabled: fetchOnMount,
    }
  );
};

export const useFetchLeadReportQuery = (
  reportRequestParams: ReportRequestParams,
  fetchOnMount: boolean = true
) => {
  const fetchLeadReport = async (): Promise<Report[]> => {
    const path = buildReportsUrl(
      config.api.reportService.getLeadReport,
      reportRequestParams
    );
    const response = await client.get<any>(path);
    return response.data.response;
  };

  return useQuery(["fetchLeadReport", reportRequestParams], fetchLeadReport, {
    retry: false,
    enabled: fetchOnMount,
  });
};
