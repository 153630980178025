import { Box, Grid2 as Grid } from "@mui/material";
import {
  accountActiveMenuItemAtom,
  isCreateLocationComponentActiveAtom,
  isCreateContactComponentActiveAtom,
  selectedAccountIdAtom,
  isCreateAccountModeAtom,
  userAtom,
} from "../../atoms";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { AccountAccordion } from "./AccountAccordion";
import { useLocation, useParams } from "react-router-dom";
import { SadmCreateAccountRoute } from "../../routes";
import { Role } from "../../enums";
import { AccountTitle } from "./AccountTitle";
import { accountMenus } from "./AccountMenus";

export const AccountDetail = () => {
  const [hasSettled, setHasSettled] = useState(false);
  const [user] = useAtom(userAtom);
  const [, setSelectedAccountId] = useAtom(selectedAccountIdAtom);
  const [isCreateAccountMode, setIsCreateAccountMode] = useAtom(
    isCreateAccountModeAtom
  );
  const [activeMenuItem] = useAtom(accountActiveMenuItemAtom);
  const [, setLocationCreateComponentActive] = useAtom(
    isCreateLocationComponentActiveAtom
  );
  const [, setContactCreateComponentActive] = useAtom(
    isCreateContactComponentActiveAtom
  );
  const params = useParams();
  const location = useLocation();

  useEffect(() => {
    const timer = setTimeout(() => {
      setHasSettled(true);
    }, 100);

    return () => clearTimeout(timer);
  }, [isCreateAccountMode]);

  useEffect(() => {
    if (location.pathname.split("/").pop() === SadmCreateAccountRoute.path) {
      setIsCreateAccountMode(true);
    } else if (params.accountId && user && user.roleCode !== Role.Customer) {
      setSelectedAccountId(Number(params.accountId));
    }
  }, [params]);

  useEffect(() => {
    if (activeMenuItem.name === "location") {
      setLocationCreateComponentActive(false);
    } else if (activeMenuItem.name === "contact") {
      setContactCreateComponentActive(false);
    }
  }, [activeMenuItem]);

  return (
    <Box
      sx={{
        minHeight: "715px",
        display: "flex",
        width: "100%",
        flexGrow: 1,
        flexDirection: "column",
      }}
    >
      {hasSettled && !isCreateAccountMode && <AccountTitle />}
      <Grid
        container
        direction={"column"}
        spacing={2}
        gap={0}
        sx={{
          width: "100%",
          height: "100%",
          borderRadius: "10px",
          direction: "row",
          py: "1rem",
        }}
      >
        {accountMenus.map((menu, index) => (
          <AccountAccordion key={index} menu={menu} />
        ))}
      </Grid>
    </Box>
  );
};
