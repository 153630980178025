import {
  Backdrop,
  BackdropProps,
  Box,
  CircularProgress,
  SxProps,
} from "@mui/material";
import React, { useEffect, useState } from "react";

type AutoRetryImageProps = {
  sx?: SxProps;
  src?: string;
  alt?: string;
  defaultImage?: string;
  maxRetries?: number;
  retryDelay?: number;
  backdropProps?: Partial<BackdropProps>;
};

export const AutoRetryImage = ({
  sx,
  src,
  alt,
  defaultImage,
  backdropProps,
  maxRetries = 5,
  retryDelay = 500,
}: AutoRetryImageProps) => {
  const [retryCount, setRetryCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [imgSrc, setImgSrc] = useState<string | undefined>("empty");

  useEffect(() => {
    setImgSrc(src);
    setLoading(true);
  }, [src]);

  const handleImageError = () => {
    setLoading(true);
    if (retryCount < maxRetries) {
      setTimeout(() => {
        setRetryCount((prevCount) => prevCount + 1);

        if (imgSrc === src) {
          setImgSrc("empty");
        } else {
          setImgSrc(src);
        }
      }, retryDelay);
    } else {
      setImgSrc(defaultImage || "img.jpg");
      setLoading(false);
      console.warn("Failed to load logo image after multiple attempts.");
    }
  };

  const handleImageLoad = () => {
    setRetryCount(0);
    setLoading(false);
  };

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Box
        component="img"
        sx={sx}
        src={imgSrc}
        alt={alt}
        onError={handleImageError}
        onLoad={handleImageLoad}
      />
      <Backdrop
        sx={{
          color: "#000",
          zIndex: 1000,
          position: "absolute",
          opacity: 0,
          backgroundColor: "white",
          height: "100%",
          ...backdropProps?.sx,
        }}
        open={loading}
      >
        <CircularProgress />
      </Backdrop>
    </Box>
  );
};
