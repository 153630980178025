import { Moment } from "moment";
import { InvoiceStatus, InvoicePaymentStatus, Role } from "../enums";
import { ExtendedInvoiceStatus } from "../types";
import { hasDatePassed } from "./dateUtil";

export const getInvoiceStatus = (
  status: InvoiceStatus,
  dueDate: string | Moment,
  role: Role
): ExtendedInvoiceStatus => {
  if (
    [
      InvoiceStatus.InProgress,
      InvoiceStatus.SendToCustomer,
      InvoiceStatus.SendToQuickbooks,
    ].includes(status)
  ) {
    return hasDatePassed(dueDate)
      ? InvoicePaymentStatus.Overdue
      : InvoicePaymentStatus.WithinDue;
  }

  if (status === InvoiceStatus.PaymentReceived && role === Role.Customer) {
    return InvoicePaymentStatus.Paid;
  }

  return status;
};
