import { AccountMenuItem } from "../../types";
import { AccountProfile } from "../profile";
import { Contact } from "../contact";
import { Billing } from "../billing";
import { Location } from "../location";
import { PriceDetails } from "../price-details";
import { Fleet } from "../fleet";
import { Invoice } from "../invoice";

export const accountMenus: AccountMenuItem[] = [
    {
      name: "profile",
      displayName: "Profile",
      component: <AccountProfile />,
    },
    {
      name: "contact",
      displayName: "Contact",
      component: <Contact />,
      allowCreate: true,
    },
    {
      name: "billing",
      displayName: "Billing",
      component: <Billing />,
    },
    {
      name: "location",
      displayName: "Location",
      component: <Location />,
      allowCreate: true,
    },
    {
      name: "fleet",
      displayName: "Fleet",
      component: <Fleet />,
    },
    {
      name: "priceDetails",
      displayName: "Price Details",
      component: <PriceDetails />,
    },
    {
      name: "invoices",
      displayName: "Invoices",
      component: <Invoice />,
    },
  ];
  
  export const accountMenuDefault: AccountMenuItem = accountMenus[0];