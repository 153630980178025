import { Box, Grid2 as Grid } from "@mui/material";
import { Autocomplete, DatePicker, SelectBox, TextField } from "../../common";
import {
  Account,
  DropdownItem,
  ModalForm,
  UnWashedFleet,
  WashInventoryFormData,
  WashItem,
  WashType,
} from "../../../types";
import { useValidateForm } from "../../../hooks";
import { washInventorySchema } from "../../../validations";
import { selectedOrgIdAtom } from "../../../atoms";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import {
  useFetchAllAccountsSummaryQuery,
  useFetchUnwashedAccountFleetsQuery,
  useFetchWashTypesQuery,
} from "../../../api";
import {
  AccountModel,
  LocationModel,
  UserModel,
  WashTypeModel,
} from "../../../models";
import { useFetchLeadsQuery } from "../../../api/userService";
import Loading from "../../common/loading/Loading";
import { utcStringToMoment } from "../../../utils";

type FormData = WashInventoryFormData;

type Props = ModalForm<WashItem, FormData> & { isCreateMode: boolean };

export const ManageWashInventoryForm = ({
  id,
  defaultValues,
  isCreateMode = false,
  handleEdit,
}: Props) => {
  const { register, handleSubmit, errors, control, setValue } =
    useValidateForm(washInventorySchema);

  const [washTypes, setWashTypes] = useState<WashType[]>([]);
  const [washType, setWashType] = useState<WashType | undefined>();
  const [unwashedAccountFleets, setUnwashedAccountFleets] =
    useState<UnWashedFleet[]>();
  const [unwashedAccountFleetDropdown, setUnwashedAccountFleetDropdown] =
    useState<DropdownItem[]>();
  const [selectedAccountId, setSelectedAccountId] = useState<number>(0);
  const [selectedLocationNumber, setSelectedLocationNumber] =
    useState<number>(0);
  const [washTypeDropDown, setWashTypeDropDown] = useState<DropdownItem[]>([]);
  const [isMeasurementInHours, setIsMeasurementInHours] = useState(false);
  const [leadUserDropDown, setLeadUserDropDown] = useState<DropdownItem[]>([]);
  const [accountLocationsDropdown, setAccountLocationsDropdown] = useState<
    DropdownItem[]
  >([]);
  const [accountsDropdown, setAccountsDropdown] = useState<DropdownItem[]>([]);
  const [selectedOrgId] = useAtom(selectedOrgIdAtom);
  const fetchUnwashedAccountFleetsQuery = useFetchUnwashedAccountFleetsQuery(
    selectedOrgId,
    selectedAccountId
  );
  const fetchAccountsSummaryQuery =
    useFetchAllAccountsSummaryQuery(selectedOrgId);
  const fetchWashTypesQuery = useFetchWashTypesQuery(selectedOrgId);
  const fetchLeadUsersQuery = useFetchLeadsQuery(selectedOrgId);

  const [summary, setSummary] = useState<any[]>([]);

  const getLocationsByAccountId = (
    accountId: number
  ): { locationNumber: number; name: string }[] => {
    const account = summary.find((acc) => acc.accountId === accountId);
    return account?.locations || [];
  };

  const handleAccountChange = (accountId: number) => {
    const locations = getLocationsByAccountId(accountId);
    const dropdown = LocationModel.buildLocationDropdown(locations);
    setAccountLocationsDropdown(dropdown);
    setValue("locationNumber", 0);
  };

  const handleWashTypeChange = (washTypeId: number) => {
    const selectedWashType = washTypes.find(
      (washType) => washType.id === washTypeId
    );
    setWashType(selectedWashType);
    const unitOfMeasurement = selectedWashType?.unitOfMeasurement || "";
    setIsMeasurementInHours(unitOfMeasurement === "hours");
    setValue("unitOfMeasurement", unitOfMeasurement);
  };

  const filteredVehicleNumbers = (): DropdownItem[] => {
    if (!unwashedAccountFleets) {
      return [];
    }
    const result = unwashedAccountFleets
      .filter(
        (item) =>
          item.accountId === selectedAccountId &&
          item.washTypeId === washType?.id &&
          item.locationNumber === selectedLocationNumber
      )
      .map((item) => ({
        key: item.vehicleNumber,
        value: item.vehicleNumber,
      }));

    return result;
  };

  useEffect(() => {
    const fleetList = filteredVehicleNumbers();
    setUnwashedAccountFleetDropdown(fleetList);
  }, [selectedAccountId, washType, selectedLocationNumber]);

  useEffect(() => {
    if (selectedAccountId > 0) {
      fetchUnwashedAccountFleetsQuery.refetch();
    }
  }, [selectedAccountId]);

  useEffect(() => {
    if (defaultValues) {
      handleAccountChange(defaultValues.accountId || 0);
      handleWashTypeChange(defaultValues.washTypeId || 0);
      setValue("locationNumber", defaultValues.locationNumber);
      setValue("endTime", utcStringToMoment(defaultValues.endTime));

      if (defaultValues.quantity) {
        setValue("hours", defaultValues.quantity.toString());
      } else {
        setValue("vehicleNumbers", [defaultValues.vehicleNumber]);
      }
    }
  }, [summary]);

  useEffect(() => {
    if (fetchAccountsSummaryQuery.isSuccess) {
      const accountData = fetchAccountsSummaryQuery.data;
      setSummary(accountData);

      const accountList: Account[] = accountData.map(({ accountId, name }) => ({
        accountId,
        accountName: name,
      }));
      const dropdown = AccountModel.buildAccountsDropdown(accountList);
      setAccountsDropdown(dropdown);
    } else {
      setSummary([]);
    }
  }, [fetchAccountsSummaryQuery.isSuccess, fetchAccountsSummaryQuery.data]);

  useEffect(() => {
    if (fetchUnwashedAccountFleetsQuery.isSuccess) {
      setUnwashedAccountFleets(fetchUnwashedAccountFleetsQuery.data);
    } else {
      setUnwashedAccountFleets([]);
    }
  }, [
    fetchUnwashedAccountFleetsQuery.isSuccess,
    fetchUnwashedAccountFleetsQuery.data,
    fetchUnwashedAccountFleetsQuery.refetch,
    fetchUnwashedAccountFleetsQuery.isFetching,
  ]);

  useEffect(() => {
    if (fetchWashTypesQuery.isSuccess) {
      setWashTypes(fetchWashTypesQuery.data);
      const dropdown: DropdownItem[] = WashTypeModel.buildWashTypeDropDown(
        fetchWashTypesQuery.data
      );
      setWashTypeDropDown(dropdown);
    }
  }, [fetchWashTypesQuery.isSuccess, fetchWashTypesQuery.data]);

  useEffect(() => {
    if (fetchLeadUsersQuery.isSuccess) {
      const dropdown: DropdownItem[] = UserModel.buildLeadUserDropdown(
        fetchLeadUsersQuery.data
      );
      setLeadUserDropDown(dropdown);
    }
  }, [fetchLeadUsersQuery.isSuccess, fetchLeadUsersQuery.data]);

  const handleFormSubmit = (data: WashInventoryFormData) => {
    handleEdit({
      ...defaultValues,
      ...data,
      ...(isMeasurementInHours
        ? { quantity: Number(data.hours), vehicleNumbers: ["N/A"] }
        : { quantity: 1, vehicleNumbers: data.vehicleNumbers }),
    });
  };

  return (
    <Box
      onSubmit={handleSubmit(handleFormSubmit)}
      component="form"
      id={id}
      noValidate
      sx={{ height: "100%" }}
    >
      <Grid
        container
        size={{ sm: 12 }}
        direction={"column"}
        gap={3}
        sx={{
          borderRadius: "10px",
          p: 3,
          mb: 2,
          width: "100%",
        }}
      >
        <Grid size={12} display={isCreateMode ? "block" : "none"}>
          {accountsDropdown.length > 0 && (
            <SelectBox
              label="Account Name"
              name="accountId"
              dropdown={[{ key: "Select", value: 0 }, ...accountsDropdown]}
              register={register}
              onChange={(e) => {
                handleAccountChange(e.target.value);
                setSelectedAccountId(e.target.value);
              }}
              error={errors["accountId"]}
              valueAsNumber={true}
              defaultValue={defaultValues?.accountId || 0}
            />
          )}
        </Grid>
        <Grid size={12} display={isCreateMode ? "block" : "none"}>
          <SelectBox
            label="Location Name"
            name="locationNumber"
            dropdown={[
              { key: "Select", value: 0 },
              ...accountLocationsDropdown,
            ]}
            register={register}
            onChange={(e) => {
              setSelectedLocationNumber(e.target.value);
            }}
            error={errors["locationNumber"]}
            valueAsNumber={true}
            defaultValue={defaultValues?.locationNumber || 0}
          />
        </Grid>
        <Grid size={12}>
          {leadUserDropDown.length > 0 && (
            <SelectBox
              label="Lead"
              name="leadId"
              dropdown={[{ key: "Select", value: 0 }, ...leadUserDropDown]}
              register={register}
              error={errors["leadId"]}
              defaultValue={defaultValues?.leadId || 0}
              valueAsNumber={true}
            />
          )}
        </Grid>
        <Grid size={12}>
          <DatePicker
            defaultTimeNow
            disableFuture
            label="Date"
            name="endTime"
            control={control}
            error={errors["endTime"]}
            defaultValue={utcStringToMoment(defaultValues?.endTime || "")}
          />
        </Grid>
        <Grid size={12}>
          {washTypeDropDown.length > 0 && (
            <SelectBox
              label="Wash Type"
              name="washTypeId"
              dropdown={[{ key: "Select", value: 0 }, ...washTypeDropDown]}
              register={register}
              onChange={(e) => handleWashTypeChange(Number(e.target.value))}
              error={errors["washTypeId"]}
              defaultValue={defaultValues?.washTypeId || 0}
              valueAsNumber={true}
            />
          )}
        </Grid>
        <Grid size={12} display={"none"}>
          <TextField
            label="Unit of Measurement"
            name="unitOfMeasurement"
            register={register}
            defaultValue={defaultValues?.vehicleNumber ? "quantity" : "hours"}
            value={washType?.unitOfMeasurement}
            error={errors["unitOfMeasurement"]}
          />
        </Grid>
        {isMeasurementInHours ? (
          <Grid size={12}>
            <TextField
              label="Hours"
              name="hours"
              register={register}
              error={errors["hours"]}
              defaultValue={defaultValues?.quantity.toString() || "1"}
            />
          </Grid>
        ) : (
          <Grid size={12}>
            <Autocomplete
              dropdown={unwashedAccountFleetDropdown || []}
              control={control}
              defaultValues={
                defaultValues?.vehicleNumber
                  ? [defaultValues?.vehicleNumber]
                  : []
              }
              label="Vehicle Numbers"
              name="vehicleNumbers"
              error={errors["vehicleNumbers"]}
            />
          </Grid>
        )}
      </Grid>
      {(fetchLeadUsersQuery.isLoading ||
        fetchWashTypesQuery.isLoading ||
        fetchAccountsSummaryQuery.isLoading) && (
        <Loading
          message=" "
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 2,
            backgroundColor: "rgba(255, 255, 255, 0.6)",
          }}
        />
      )}
    </Box>
  );
};
