import { Role } from "../enums";
import { Route, User } from "../types";
import {
  AdminDashboardRoute,
  AdminInvoiceDetailsRoute,
  ChangePasswordRoute,
  CustomerDashboardRoute,
  CustomerInvoiceDetailsRoute,
  LoginRoute,
  SadmDashboardRoute,
  SadmInvoiceDetailsRoute,
} from "../routes";

export const getDashboardPath = (user: User | null): string => {
  let path = LoginRoute.path;

  if (!user) {
    return path;
  }

  const { id, orgId, roleCode, isPasswordReset } = user;

  if (isPasswordReset) {
    path = ChangePasswordRoute.path;
    return path
      .replace(":orgId", orgId.toString())
      .replace(":userId", id.toString());
  }

  switch (roleCode) {
    case Role.SuperAdmin:
      return SadmDashboardRoute.path;
    case Role.Admin:
      return AdminDashboardRoute.path;
    default:
      return CustomerDashboardRoute.path;
  }
};

export const buildQueryString = <T>(options: T) => {
  const queryParams = [];

  if (!options) return "";

  for (const key in options) {
    if (Object.prototype.hasOwnProperty.call(options, key)) {
      const value = options[key];
      if (value !== undefined) {
        queryParams.push(`${key}=${value}`);
      }
    }
  }

  return "?" + queryParams.join("&");
};

export const getInvoiceDetailsRoute = (user: User): Route => {
  if (user.roleCode === Role.SuperAdmin) {
    return SadmInvoiceDetailsRoute;
  } else if (user.roleCode === Role.Admin) {
    return AdminInvoiceDetailsRoute;
  } else {
    return CustomerInvoiceDetailsRoute;
  }
};

export const buildBreadcrumbs = (
  routes: Route[] | undefined,
  pathname: string
): { name: string; path: string }[] => {
  if (!routes) return [];

  for (const route of routes) {
    const routeSegments = route.path.split("/");
    const pathSegments = pathname.split("/");

    const isMatch = routeSegments.every((segment, index) => {
      return (
        segment === pathSegments[index] ||
        (segment.startsWith(":") && pathSegments[index])
      );
    });

    if (isMatch) {
      const remainingPathname = pathSegments
        .slice(routeSegments.length)
        .join("/");
      const childBreadcrumbs = buildBreadcrumbs(
        route.childRoutes,
        remainingPathname
      );

      let currentDisplayName = route.displayName;
      let currentPath = route.path;

      const dynamicSegmentIndex = routeSegments.findIndex((segment) =>
        segment.startsWith(":")
      );
      if (dynamicSegmentIndex !== -1) {
        const dynamicValue = pathSegments[dynamicSegmentIndex];
        currentDisplayName = dynamicValue || route.displayName;
        currentPath = pathname
          .split("/")
          .slice(0, dynamicSegmentIndex + 1)
          .join("/");
      }

      const breadcrumbs = [
        { name: currentDisplayName, path: currentPath },
        ...childBreadcrumbs,
      ];

      const finalBreadcrumb = breadcrumbs[breadcrumbs.length - 1];

      const queryParams = window.location.search;
      if (childBreadcrumbs.length === 0 && queryParams) {
        finalBreadcrumb.path = `${finalBreadcrumb.path}${queryParams}`;
      } else if (childBreadcrumbs.length > 0) {
        // eslint-disable-next-line no-self-assign
        finalBreadcrumb.path = finalBreadcrumb.path;
      }

      return breadcrumbs;
    }
  }

  return [];
};

export const filterQueryParamsToKeep = (
  queryParams: URLSearchParams,
  queryParamsToKeep?: string[]
): URLSearchParams => {
  const filteredParams = new URLSearchParams();
  (queryParamsToKeep || []).forEach((key) => {
    const queryParam = queryParams.get(key);
    if (queryParam && queryParam !== null) {
      filteredParams.set(key, queryParam);
    }
  });

  return filteredParams;
};
