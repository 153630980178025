import { Grid2 as Grid } from "@mui/material";
import { SelectBox, TextField } from "../../common";
import { Button } from "../../common";
import { Address } from "../../../types";
import { useValidateForm } from "../../../hooks";
import { editOrganizationAddressSchema } from "../../../validations";
import { useState } from "react";
import { STATES } from "../../../constants";

type EditAddressInfoProps = {
  data?: Address;
  handleEdit: (data: Address) => void;
  handleDiscard: () => void;
};

export const EditAddressInfo = ({
  data,
  handleEdit,
  handleDiscard,
}: EditAddressInfoProps) => {
  const [hasChanges, setHasChanges] = useState(false);

  const { register, handleSubmit, errors, reset } = useValidateForm(
    editOrganizationAddressSchema
  );

  const handleChange = () => {
    setHasChanges(true);
  };

  return (
    <Grid
      container
      component={"form"}
      onSubmit={handleSubmit(handleEdit)}
      noValidate
      size={{ sm: 12, md: 6 }}
      direction={"column"}
      spacing={4}
      sx={{
        boxShadow: "0px 0px 4px 0px #00000040",
        borderRadius: "10px",
        p: 4,
        position: "relative",
        justifyContent: "space-between",
      }}
    >
      <Grid container size={12}>
        <Grid container size={{ xs: 12, sm: 6, md: 12, lg: 6 }}>
          <Grid container size={12}>
            <TextField
              label="Email"
              name="email"
              register={register}
              error={errors["email"]}
              defaultValue={data && data.email}
              onChange={handleChange}
              maxLength={50}
              required
            />
          </Grid>
          <Grid container size={12}>
            <TextField
              label="Website (Optional)"
              name="website"
              register={register}
              error={errors["website"]}
              defaultValue={data && data.website}
              onChange={handleChange}
              maxLength={50}
            />
          </Grid>
          <Grid container size={12}>
            <TextField
              label="Instruction (Optional)"
              name="instruction"
              register={register}
              error={errors["instruction"]}
              defaultValue={data && data.instruction}
              onChange={handleChange}
              maxLength={200}
            />
          </Grid>
        </Grid>
        <Grid container size={{ xs: 12, sm: 6, md: 12, lg: 6 }}>
          <Grid container size={12}>
            <TextField
              label="Address Line 1"
              name="addressLine1"
              register={register}
              error={errors["addressLine1"]}
              defaultValue={data && data.addressLine1}
              onChange={handleChange}
              maxLength={50}
              required
              />
          </Grid>
          <Grid container size={12}>
            <TextField
              label="Address Line 2 (Optional)"
              name="addressLine2"
              register={register}
              error={errors["addressLine2"]}
              defaultValue={data && data.addressLine2}
              onChange={handleChange}
              maxLength={50}
            />
          </Grid>
          <Grid container size={12}>
            <TextField
              label="City"
              name="city"
              register={register}
              error={errors["city"]}
              defaultValue={data && data.city}
              onChange={handleChange}
              maxLength={50}
              required
            />
          </Grid>
        </Grid>
        <Grid container size={12}>
          <Grid container size={6}>
            <SelectBox
              register={register}
              defaultValue={data && data.state}
              label="State"
              name="state"
              dropdown={STATES}
              error={errors["state"]}
              onChange={handleChange}
            />
          </Grid>
          <Grid container size={6}>
            <TextField
              type="number"
              label="ZIP"
              name="zip"
              register={register}
              error={errors["zip"]}
              defaultValue={data && data.zip}
              onChange={handleChange}
              maxLength={10}
              required
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        size={{ xs: 12 }}
        flexDirection={{ xs: "column", md: "row" }}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        <Grid size={{ xs: 12, md: 6 }}>
          <Button
            label="Discard"
            variantType="secondary"
            sx={{
              padding: "0.5rem",
              width: "100%",
            }}
            onClick={() => {
              reset();
              handleDiscard();
            }}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <Button
            type={hasChanges ? "submit" : undefined}
            disabled={!hasChanges}
            label={"Save Changes"}
            sx={{
              padding: "0.5rem",
              width: "100%",
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
