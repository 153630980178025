import { Box, Link, Typography } from "@mui/material";
import { Grid2 as Grid, Paper, styled } from "@mui/material";
import { addProfileIcon } from "../../../../../assets";
import { theme } from "../../../../../utils";
import { Button, ImageCropModal, TextField } from "../../../../common";
import { useValidateForm } from "../../../../../hooks";
import { editOrganizationProfileHeaderSchema } from "../../../../../validations";
import { OrganizationProfileInfo } from "../../../../../types";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import {
  isCreateOrganizationModeAtom,
  toastOptionsAtom,
} from "../../../../../atoms";
import { useAtom } from "jotai";

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  flexGrow: 1,
  borderRadius: "10px",
  boxShadow: "none",
}));

type EditOrganizationProfileHeaderProps = {
  defaultValues?: OrganizationProfileInfo;
  loading?: boolean;
  handleEdit: (data: {
    data: Pick<OrganizationProfileInfo, "name" | "logo">;
    logo?: File;
  }) => void;
  handleDiscard: () => void;
};

export const EditOrganizationProfileHeader = ({
  defaultValues,
  loading,
  handleEdit,
  handleDiscard,
}: EditOrganizationProfileHeaderProps) => {
  const [, setToastOptions] = useAtom(toastOptionsAtom);
  const [isCreateOrganizationMode] = useAtom(isCreateOrganizationModeAtom);
  const [cropModalOpen, setCropModalOpen] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [uploadedImage, setUploadedImage] = useState<File>();
  const [uploadedImageUrl, setUploadedImageUrl] = useState<string>();
  const uploadInputRef = useRef<HTMLInputElement | null>(null);
  const { register, handleSubmit, errors } = useValidateForm(
    editOrganizationProfileHeaderSchema
  );

  useEffect(() => {
    if (uploadedImage) {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        setUploadedImageUrl(e.target.result as string);
      };
      reader.readAsDataURL(uploadedImage);
    }
  }, [uploadedImage]);

  const handleChange = () => {
    setHasChanges(true);
  };

  const handleEditWrapper = (
    data: Pick<OrganizationProfileInfo, "name" | "logo">
  ) => {
    if (uploadedImage) {
      const timestamp = new Date().toISOString().replace(/[:.-]/g, "");
      const fileExtension = uploadedImage.name.split(".").pop();
      const fileName = `logo_${timestamp}.${fileExtension}`;

      data.logo = fileName;
    }

    handleEdit({ data, logo: uploadedImage });
  };

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;

    if (file && file.type.startsWith("image/")) {
      setUploadedImage(file);
      setCropModalOpen(true);
      if (!isCreateOrganizationMode) {
        setHasChanges(true);
      }
    } else {
      setToastOptions({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    }

    event.target.value = "";
  };

  const handleConfirm = (file: File) => {
    setUploadedImage(file);
    setCropModalOpen(false);
  };

  return (
    <>
      <Box
        onSubmit={handleSubmit(handleEditWrapper)}
        component={"form"}
        noValidate
        sx={{
          height: "100%",
        }}
      >
        <Grid
          container
          spacing={0}
          gap={0}
          sx={{
            width: "100%",
            height: "100%",
            minHeight: "150px",
            px: 4,
          }}
        >
          <Grid
            container
            size={{ lg: 5, md: 6, sm: 3, xs: 12 }}
            sx={{
              pt: { xs: 4, lg: 0 },
            }}
          >
            <Grid container size={{ lg: 4, md: 6, xs: 12 }}>
              <Item
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  px: "auto",
                }}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  position={"relative"}
                  sx={{ borderRadius: "50%", overflow: "hidden" }}
                >
                  <input
                    ref={uploadInputRef}
                    type="file"
                    name="logo"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={handleFileUpload}
                  />
                  <Box
                    component="img"
                    src={uploadedImageUrl ? uploadedImageUrl : addProfileIcon}
                    onClick={() =>
                      uploadInputRef.current && uploadInputRef.current.click()
                    }
                    alt="Organization logo"
                    sx={{
                      width: { xs: 60, sm: 70 },
                      height: { xs: 60, sm: 70 },
                      zIndex: 1,
                      objectFit: "contain",
                      cursor: "pointer",
                      opacity: uploadedImageUrl ? 1 : 0.7,
                    }}
                  />
                  <Box
                    component="img"
                    src={defaultValues?.logo}
                    sx={{
                      width: 100,
                      height: 100,
                      zIndex: 0,
                      position: "absolute",
                      objectFit: "contain",
                      cursor: "pointer",
                      opacity: 0.4,
                    }}
                  />
                </Box>
              </Item>
            </Grid>
            <Grid
              container
              size={{ lg: 8, md: 6, xs: 0 }}
              sx={{
                display: {
                  xs: "none",
                  md: "flex",
                },
              }}
            >
              <Item
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "start",
                  flexDirection: "column",
                }}
              >
                <Link
                  href="#"
                  sx={{
                    textDecoration: "underline",
                    textDecorationColor: theme.palette.primary.main,
                    textDecorationSkipInk: "all",
                  }}
                >
                  <Typography
                    sx={{
                      color: theme.palette.primary.main,
                      textAlign: "left",
                    }}
                    onClick={() =>
                      uploadInputRef.current && uploadInputRef.current.click()
                    }
                  >
                    Profile image / Logo
                  </Typography>
                </Link>
                <Typography
                  sx={{
                    color: "#a6a6a6",
                    textAlign: "left",
                  }}
                >
                  (Image must be in jpeg/png format)
                </Typography>
              </Item>
            </Grid>
          </Grid>
          <Grid
            container
            size={{ lg: 5, md: 6, sm: 9, xs: 12 }}
            sx={{
              alignItems: "center",
              pl: { md: 4, xs: 0, sm: 2, lg: 5 },
              pr: { md: 4, xs: 0, sm: 2, lg: 4 },
            }}
          >
            <TextField
              register={register}
              defaultValue={defaultValues?.name}
              label="Organization Name"
              name="name"
              error={errors["name"]}
              onChange={handleChange}
              maxLength={200}
              required
            />
          </Grid>
          <Grid
            size={{ lg: 2, xs: 12 }}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-end",
              flexDirection: "column",
              py: 4,
              pl: { md: 4, xs: 0, sm: 2, lg: 0 },
              pr: { md: 4, xs: 0, sm: 2, lg: 2 },
              gap: 2,
            }}
          >
            {!isCreateOrganizationMode && (
              <Button
                label="Discard"
                variantType={"secondary"}
                sx={{
                  padding: "0.5rem",
                  width: "100%",
                }}
                onClick={handleDiscard}
              />
            )}
            <Button
              type={hasChanges ? "submit" : undefined}
              disabled={!hasChanges || loading}
              label={loading ? "Saving" : "Save Changes"}
              variantType={hasChanges ? "primary" : "secondary"}
              sx={{
                padding: "0.5rem",
                width: "100%",
                transform: isCreateOrganizationMode
                  ? "translateY(-8px)"
                  : "none",
              }}
            />
          </Grid>
        </Grid>
      </Box>
      {uploadedImage && (
        <ImageCropModal
          open={cropModalOpen}
          file={uploadedImage}
          onClose={() => {
            setCropModalOpen(false);
          }}
          onConfirm={handleConfirm}
        />
      )}
    </>
  );
};
