import { Box, Grid2 as Grid } from "@mui/material";
import { DatePicker, SelectBox, TextField } from "../../common";
import { DropdownItem, ModalForm, WashItem } from "../../../types";
import { useValidateForm } from "../../../hooks";
import {
  bulkEditWashInventorySchema,
  editWashInventorySchema,
} from "../../../validations";
import { selectedOrgIdAtom } from "../../../atoms";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { useFetchWashTypesQuery } from "../../../api";
import { UserModel, WashTypeModel } from "../../../models";
import { useFetchLeadsQuery } from "../../../api/userService";
import Loading from "../../common/loading/Loading";
import { utcStringToMoment } from "../../../utils";
import { Moment } from "moment";

type FormData = {
  washTypeId: number;
  leadId: number;
  vehicleNumber: string;
  endTime: string;
};

type Props = ModalForm<WashItem, FormData> & {
  isBulkEdit?: boolean;
};

export const BulkEditWashInventoryForm = ({
  id,
  defaultValues,
  handleEdit,
  isBulkEdit = false,
}: Props) => {
  const { register, handleSubmit, errors, control } = useValidateForm(
    isBulkEdit ? bulkEditWashInventorySchema : editWashInventorySchema
  );
  const [washTypeDropDown, setWashTypeDropDown] = useState<DropdownItem[]>([]);
  const [leadUserDropDown, setLeadUserDropDown] = useState<DropdownItem[]>([]);
  const [selectedOrgId] = useAtom(selectedOrgIdAtom);
  const fetchWashTypesQuery = useFetchWashTypesQuery(selectedOrgId);
  const fetchLeadUsersQuery = useFetchLeadsQuery(selectedOrgId);

  useEffect(() => {
    if (fetchWashTypesQuery.isSuccess) {
      const dropdown: DropdownItem[] = WashTypeModel.buildWashTypeDropDown(
        fetchWashTypesQuery.data
      );
      setWashTypeDropDown(dropdown);
    }
  }, [fetchWashTypesQuery]);

  useEffect(() => {
    if (fetchLeadUsersQuery.isSuccess) {
      const dropdown: DropdownItem[] = UserModel.buildLeadUserDropdown(
        fetchLeadUsersQuery.data
      );
      setLeadUserDropDown(dropdown);
    }
  }, [
    fetchLeadUsersQuery.isSuccess,
    fetchLeadUsersQuery.isError,
    fetchLeadUsersQuery.data,
  ]);

  const handleEditWrapper = (data: {
    washTypeId?: number;
    leadId?: number;
    vehicleNumber?: string;
    endTime?: Moment | string;
  }) => {
    handleEdit({
      ...defaultValues,
      washTypeId: Number(data.washTypeId),
      leadId: Number(data.leadId),
      vehicleNumber: String(data.vehicleNumber),
      endTime: String(data.endTime),
    });
  };

  return (
    <Box
      onSubmit={handleSubmit(handleEditWrapper)}
      component={"form"}
      id={id}
      noValidate
      sx={{ height: "100%" }}
    >
      <Grid
        container
        size={{ sm: 12 }}
        direction={"column"}
        gap={3}
        sx={{
          borderRadius: "10px",
          p: 3,
          mb: 2,
          width: "100%",
        }}
      >
        <Grid size={12}>
          <DatePicker
            defaultTimeNow
            disableFuture
            label="Date"
            name="endTime"
            control={control}
            error={errors["endTime"]}
            defaultValue={utcStringToMoment(defaultValues?.endTime || "")}
          />
        </Grid>
        <Grid size={12}>
          {washTypeDropDown && (
            <SelectBox
              label="Wash Type"
              name="washTypeId"
              dropdown={[{ key: "Select", value: 0 }, ...washTypeDropDown]}
              register={register}
              error={errors["washTypeId"]}
              defaultValue={defaultValues?.washTypeId || 0}
              valueAsNumber={true}
            />
          )}
        </Grid>
        <Grid size={12}>
          {leadUserDropDown && (
            <SelectBox
              label="Lead"
              name="leadId"
              dropdown={[{ key: "Select", value: 0 }, ...leadUserDropDown]}
              register={register}
              error={errors["leadId"]}
              defaultValue={defaultValues?.leadId || 0}
              valueAsNumber={true}
            />
          )}
        </Grid>
        <Grid size={12}>
          <TextField
            label="Vehicle Number"
            name="vehicleNumber"
            register={register}
            error={errors["vehicleNumber"]}
            defaultValue={defaultValues?.vehicleNumber}
            maxLength={50}
          />
        </Grid>
      </Grid>
      {(fetchLeadUsersQuery.isLoading || fetchWashTypesQuery.isLoading) && (
        <Loading
          message=" "
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 2,
            backgroundColor: "rgba(255, 255, 255, 0.6)",
          }}
        />
      )}
    </Box>
  );
};
