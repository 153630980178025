import React, { useEffect, useState } from "react";
import { Checkbox, Grid2, Typography } from "@mui/material";
import { DropdownItem, FleetInfo } from "../../../types";
import { SelectBox, TextField } from "../../common";
import { useFetchWashTypesQuery } from "../../../api/washTypeService";
import { selectedOrgIdAtom } from "../../../atoms";
import { useAtom } from "jotai";
import { WashTypeModel } from "../../../models/WashTypeModel";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import Loading from "../../common/loading/Loading";

type FleetFormProps = {
  fleet?: FleetInfo;
  register: UseFormRegister<any>;
  errors: FieldErrors<{
    washTypeId: number;
    vehicleNumber: string;
    isPreSelected: string;
  }>;
};

export const FleetForm: React.FC<FleetFormProps> = ({
  fleet,
  register,
  errors,
}) => {
  const [selectedOrgId] = useAtom(selectedOrgIdAtom);
  const fetchWashTypesQuery = useFetchWashTypesQuery(selectedOrgId);
  const [washTypeDropDown, setWashTypeDropDown] = useState<DropdownItem[]>([]);
  useEffect(() => {
    if (fetchWashTypesQuery.isSuccess) {
      const dropdown: DropdownItem[] = WashTypeModel.buildWashTypeDropDown(
        fetchWashTypesQuery.data
      );
      setWashTypeDropDown(dropdown);
    }
  }, [
    fetchWashTypesQuery.isSuccess,
    fetchWashTypesQuery.isError,
    fetchWashTypesQuery.isLoading,
    fetchWashTypesQuery.data,
    fetchWashTypesQuery.error,
  ]);

  return fetchWashTypesQuery.isLoading ? (
    <Loading message="Loading..." sx={{ minHeight: "300px" }} />
  ) : (
    <Grid2
      container
      size={12}
      direction={"column"}
      spacing={3}
      sx={{
        p: 4,
        mb: 2,
        width: "100%",
      }}
    >
      <Grid2>
        {washTypeDropDown && (
          <SelectBox
            label="Wash Type"
            name="washTypeId"
            dropdown={washTypeDropDown}
            register={register}
            error={errors.washTypeId}
            defaultValue={fleet?.washTypeId}
            valueAsNumber={true}
            required
          />
        )}
      </Grid2>
      <Grid2>
        <TextField
          label={"Vehicle Number"}
          name={"vehicleNumber"}
          error={errors.vehicleNumber}
          register={register}
          defaultValue={fleet?.vehicleNumber}
          maxLength={20}
          required
        />
      </Grid2>
      <Grid2 container alignItems="center" spacing={1}>
        <Typography>Preselect</Typography>
        <Checkbox
          {...(register && register("isPreSelected"))}
          defaultChecked={fleet?.isPreSelected}
        />
      </Grid2>
    </Grid2>
  );
};
