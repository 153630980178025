import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import { IconButton, Skeleton, Tooltip } from "@mui/material";
import { theme } from "../../utils";
import { useFetchAccountInfoQuery } from "../../api";
import { useAtom } from "jotai";
import { selectedAccountIdAtom, selectedOrgIdAtom } from "../../atoms";
import { useEffect, useState } from "react";
import { AccountModel } from "../../models";
import { AccountProfileInfo } from "../../types";
import { defaultProfileImage } from "../../assets";
import { AutoRetryImage } from "../common/image/AutoRetryImage";

export const AccountTitle = () => {
  const [accountInfo, setAccountInfo] = useState<AccountProfileInfo>();
  const [selectedOrgId] = useAtom(selectedOrgIdAtom);
  const [selectedAccountId] = useAtom(selectedAccountIdAtom);
  const fetchAccountInfoQuery = useFetchAccountInfoQuery(
    selectedOrgId,
    selectedAccountId || 0,
    { contacts: true },
    { enabled: selectedAccountId !== undefined }
  );

  useEffect(() => {
    if (fetchAccountInfoQuery.isSuccess) {
      const accountInfo = AccountModel.buildAccountProfile(
        fetchAccountInfoQuery.data
      );
      setAccountInfo(accountInfo);
    }
  }, [
    fetchAccountInfoQuery.isSuccess,
    fetchAccountInfoQuery.isError,
    fetchAccountInfoQuery.data,
    fetchAccountInfoQuery.error,
    fetchAccountInfoQuery.isLoading,
    fetchAccountInfoQuery.isFetching,
    fetchAccountInfoQuery.isRefetching,
  ]);

  return (
    <Card
      sx={{
        display: "flex",
        boxShadow: "0px 0px 4px 0px #00000040",
        width: "100%",
        minHeight: 120,
      }}
    >
      <CardContent sx={{ display: "flex", alignItems: "center", px: 6 }}>
        {fetchAccountInfoQuery.isLoading || fetchAccountInfoQuery.isFetching ? (
          <Box
            sx={{
              px: 4,
            }}
          >
            <Skeleton
              variant="circular"
              sx={{
                width: 60,
                height: 60,
              }}
            />
          </Box>
        ) : (
          <AutoRetryImage
            sx={{
              width: { xs: 60, sm: 70 },
              height: { xs: 60, sm: 70 },
              objectFit: "contain",
            }}
            src={accountInfo?.logo}
            alt={`${accountInfo?.accountName} Logo`}
            defaultImage={defaultProfileImage}
          />
        )}
      </CardContent>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <CardContent
          sx={{
            flex: "1 0 auto",
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {fetchAccountInfoQuery.isLoading ||
          fetchAccountInfoQuery.isFetching ? (
            <Skeleton
              variant="text"
              sx={{ width: "300px", fontSize: "1.5rem" }}
            />
          ) : (
            accountInfo &&
            accountInfo.accountName && (
              <Typography component="div" variant="h5">
                {accountInfo.accountName}
              </Typography>
            )
          )}
          {fetchAccountInfoQuery.isLoading ||
          fetchAccountInfoQuery.isFetching ? (
            <Skeleton
              variant="text"
              sx={{ width: "300px", fontSize: "1rem" }}
            />
          ) : (
            accountInfo &&
            accountInfo.contactPerson && (
              <Typography
                variant="subtitle1"
                component="div"
                sx={{ color: "text.secondary" }}
              >
                {accountInfo.contactPerson}
              </Typography>
            )
          )}
        </CardContent>
        <CardContent sx={{ display: "flex", alignItems: "center" }}>
          {accountInfo && accountInfo.contactNumber && (
            <Tooltip
              title={accountInfo.contactNumber}
              slotProps={{ tooltip: { sx: { fontSize: "1rem" } } }}
            >
              <IconButton>
                <CallIcon
                  fontSize="medium"
                  sx={{ color: theme.palette.primary.main }}
                />
              </IconButton>
            </Tooltip>
          )}
          {accountInfo && accountInfo.email && (
            <Tooltip
              title={accountInfo.email}
              slotProps={{ tooltip: { sx: { fontSize: "1rem" } } }}
            >
              <IconButton>
                <EmailIcon
                  fontSize="medium"
                  sx={{ color: theme.palette.primary.main }}
                />
              </IconButton>
            </Tooltip>
          )}
        </CardContent>
      </Box>
    </Card>
  );
};
