import { atom } from "jotai";
import { WashInventoryFilters } from "../types";
import { getDateRange } from "../utils";
import moment from "moment";

const currentDate = getDateRange("thisMonth");

export const washInventoryFiltersAtom = atom<WashInventoryFilters>({
  fromDate: moment(currentDate.startDate)
    .startOf("day")
    .utc()
    .format("YYYY-MM-DD HH:mm:ss"),
  toDate: moment(currentDate.endDate)
    .endOf("day")
    .utc()
    .format("YYYY-MM-DD HH:mm:ss"),
  sortBy: "endTime",
  sortOrder: "desc",
});

export const washInventorySelectedDateRangeAtom = atom<string>("thisMonth");
