type InvoiceHistoryProps = {
  invoiceNumber: string;
};

export const InvoiceHistory = ({ invoiceNumber }: InvoiceHistoryProps) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      History
    </div>
  );
};
