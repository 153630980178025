import {
  Box,
  CircularProgress,
  Grid2 as Grid,
  Pagination,
  SelectChangeEvent,
  useMediaQuery,
} from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { BusinessInfoTile } from "../common/tiles";
import { useAtom } from "jotai";
import {
  selectedOrgIdAtom,
  organizationsDropdownItemsAtom,
  selectedAccountIdAtom,
  userAtom,
  toastOptionsAtom,
} from "../../atoms";
import { ChangeEvent, useEffect, useState } from "react";
import { BusinessViewToolbar } from "../toolbar";
import {
  useDeleteAccountMutation,
  useFetchAccountsQuery,
  useFetchOrganizationsQuery,
} from "../../api";
import { AccountModel, OrganizationModel } from "../../models";
import { Role } from "../../enums";
import { ConfirmationModal } from "../common";
import { theme } from "../../utils";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BusinessInfo } from "../../types";
import { ORG_ID_QUERY_PARAM_KEY, ROUTE_CREATE_ACCOUNT } from "../../routes";

export const AccountLayout = () => {
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));
  const isSm = useMediaQuery(theme.breakpoints.only("sm"));
  const isLg = useMediaQuery(theme.breakpoints.only("lg"));
  const isXl = useMediaQuery(theme.breakpoints.only("xl"));
  const [queryParams, setQueryParams] = useSearchParams();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(16);
  const [searchTerm, setSearchTerm] = useState("");
  const [user] = useAtom(userAtom);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAccountId, setSelectedAccountId] = useAtom(
    selectedAccountIdAtom
  );
  const [, setToastOptions] = useAtom(toastOptionsAtom);
  const [accounts, setAccounts] = useState<BusinessInfo[]>();
  const [paginatedAccounts, setPaginatedAccounts] = useState<BusinessInfo[]>();
  const [selectedOrgId, setSelectedOrgId] = useAtom(selectedOrgIdAtom);
  const [organizationsDropdown, setOrganizationsDropdown] = useAtom(
    organizationsDropdownItemsAtom
  );
  const fetchAccountsQuery = useFetchAccountsQuery(selectedOrgId);
  const fetchOrganizationsQuery = useFetchOrganizationsQuery({
    enabled: user?.roleCode !== Role.Admin,
  });
  const deleteAccountMutation = useDeleteAccountMutation();
  const [filteredAccounts, setFilteredAccounts] = useState<BusinessInfo[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (isXs) setPageSize(10);
    else if (isSm) setPageSize(10);
    else if (isLg) setPageSize(12);
    else if (isXl) setPageSize(16);
  }, [isXs, isSm, isLg, isXl]);

  useEffect(() => {
    setFilteredAccounts(
      (accounts || []).filter((item) =>
        item.name.toLowerCase().includes(searchTerm)
      )
    );
    setPage(1);
  }, [searchTerm, accounts]);

  const handleClick = (accountId: number) => {
    navigate({
      pathname: accountId.toString(),
      search: `orgId=${selectedOrgId}&accountId=${accountId}`,
    });
  };

  const getPaginatedItems = (
    array: BusinessInfo[],
    page: number,
    pageSize: number
  ) => {
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return (array || []).slice(startIndex, endIndex);
  };

  const handleCreate = () => {
    navigate(ROUTE_CREATE_ACCOUNT);
  };

  const handleChangeDropdown = (event: SelectChangeEvent) => {
    const orgId = Number(event.target.value);
    queryParams.set(ORG_ID_QUERY_PARAM_KEY, orgId.toString());
    setQueryParams(queryParams);
    setSelectedOrgId(orgId);
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const handleOpenModal = () => setIsModalOpen(true);

  const handleCloseModal = () => {
    setSelectedAccountId(undefined);
    setIsModalOpen(false);
  };

  const handleClickDelete = (id: number) => {
    setSelectedAccountId(id);
    handleOpenModal();
  };

  const handleConfirmDelete = () => {
    if (selectedAccountId) {
      deleteAccountMutation.mutate({
        accountId: selectedAccountId,
        orgId: selectedOrgId,
      });
      handleCloseModal();
    }
  };

  useEffect(() => {
    setSearchTerm("");
  }, [page]);

  useEffect(() => {
    if (filteredAccounts) {
      setPaginatedAccounts(getPaginatedItems(filteredAccounts, page, pageSize));
    }
  }, [filteredAccounts, page, pageSize]);

  useEffect(() => {
    if (fetchOrganizationsQuery.isSuccess) {
      const dropdown = OrganizationModel.buildOrganizationDropdown(
        fetchOrganizationsQuery.data
      );
      const orgIdFromQueryParams = queryParams.get("orgId");
      const orgIdFromQueryParamsExists = fetchOrganizationsQuery.data.some(
        (e) => orgIdFromQueryParams && e.orgId === Number(orgIdFromQueryParams)
      );
      setOrganizationsDropdown(dropdown);
      setSelectedOrgId(
        orgIdFromQueryParamsExists
          ? Number(orgIdFromQueryParams)
          : fetchOrganizationsQuery.data[0].orgId
      );
    }
  }, [
    fetchOrganizationsQuery.isSuccess,
    fetchOrganizationsQuery.isError,
    fetchOrganizationsQuery.data,
    fetchOrganizationsQuery.error,
    fetchOrganizationsQuery.isLoading,
  ]);

  useEffect(() => {
    if (fetchAccountsQuery.isSuccess) {
      const accounts = AccountModel.buildAccountBusinessInfo(
        fetchAccountsQuery.data
      );
      setAccounts(accounts);
    }
    if (fetchAccountsQuery.isError) {
      setAccounts([]);
      setFilteredAccounts([]);
    }
    setPage(1);
  }, [
    fetchAccountsQuery.isSuccess,
    fetchAccountsQuery.isError,
    fetchAccountsQuery.data,
    fetchAccountsQuery.error,
    fetchAccountsQuery.isLoading,
  ]);

  useEffect(() => {
    if (deleteAccountMutation.isSuccess) {
      fetchAccountsQuery.refetch();

      setToastOptions({
        open: true,
        message: "Account deleted successfully",
        severity: "success",
      });
    }

    if (deleteAccountMutation.isError) {
      setToastOptions({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    }
  }, [
    deleteAccountMutation.isSuccess,
    deleteAccountMutation.isError,
    deleteAccountMutation.isLoading,
    deleteAccountMutation.data,
    deleteAccountMutation.error,
  ]);

  return (
    <Box sx={{ width: "100%", height: "calc(100vh - 150px)" }}>
      <Box
        sx={{
          overflow: "auto",
          px: "5px",
          display: "flex",
          width: "100%",
          height: "100%",
          flexGrow: 1,
          flexDirection: "column",
        }}
      >
        <BusinessViewToolbar
          addLabel={"account"}
          dropdown={organizationsDropdown}
          dropdownValue={selectedOrgId}
          dropdownOnChange={handleChangeDropdown}
          handleCreate={handleCreate}
          handleSearch={handleSearch}
        />
        <Grid
          container
          direction={"row"}
          spacing={2}
          sx={{
            width: "100%",
            height: "100%",
            flexGrow: 1,
            alignItems:
              fetchAccountsQuery.isLoading ||
              deleteAccountMutation.isLoading ||
              fetchAccountsQuery.isRefetching ||
              (fetchAccountsQuery.isSuccess && !paginatedAccounts) ||
              fetchAccountsQuery.isError
                ? "center"
                : "none",
            alignContent:
              fetchAccountsQuery.isLoading ||
              deleteAccountMutation.isLoading ||
              fetchAccountsQuery.isRefetching ||
              (fetchAccountsQuery.isSuccess && !paginatedAccounts) ||
              fetchAccountsQuery.isError
                ? "none"
                : "baseline",
          }}
        >
          {fetchAccountsQuery.isLoading ||
          fetchAccountsQuery.isFetching ||
          fetchAccountsQuery.isRefetching ||
          deleteAccountMutation.isLoading ||
          (fetchAccountsQuery.isSuccess && !paginatedAccounts) ? (
            <Grid
              size={12}
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Grid>
          ) : (fetchAccountsQuery.isSuccess &&
              accounts &&
              paginatedAccounts &&
              paginatedAccounts.length === 0) ||
            !paginatedAccounts ||
            fetchAccountsQuery.isError ? (
            <Grid
              size={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              Nothing to show
            </Grid>
          ) : (
            paginatedAccounts.map((item, index) => (
              <Grid key={index} size={{ lg: 4, sm: 6, xs: 12, xl: 3 }}>
                <BusinessInfoTile
                  data={item}
                  handleClick={handleClick}
                  handleDelete={handleClickDelete}
                />
              </Grid>
            ))
          )}
        </Grid>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50px",
        }}
      >
        <Pagination
          count={
            filteredAccounts && filteredAccounts.length > 0
              ? Math.ceil(filteredAccounts.length / pageSize)
              : 1
          }
          page={page}
          onChange={(event: React.ChangeEvent<unknown>, value: number) => {
            setPage(value);
          }}
          color="primary"
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        />
      </Box>
      <ConfirmationModal
        open={isModalOpen}
        onClose={handleCloseModal}
        onConfirm={handleConfirmDelete}
        icon={
          <DeleteOutlineOutlinedIcon
            fontSize="medium"
            sx={{ color: theme.palette.primary.main }}
          />
        }
        title={"Delete Account"}
        message={"Are you sure that you want to delete this account?"}
      />
    </Box>
  );
};
