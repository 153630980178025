import { defaultProfileImage } from "../assets";
import { ActiveStatus, ChargeType } from "../enums";
import {
  AccountBasicInfo,
  FleetInfo,
  ChargeInformation,
  ChargeInformationRow,
  LocationCharge,
  AccountInfo,
  AccountProfileInfo,
  FleetForDatagrid,
  DropdownItem,
  Account,
} from "../types";
import { BusinessInfo } from "../types";

export class AccountModel {
  public static buildAccountProfile(
    accountInfo: AccountInfo
  ): AccountProfileInfo {
    return {
      accountId: accountInfo.baseInfo.accountId,
      accountName: accountInfo.baseInfo.accountName,
      contactPerson: accountInfo.baseInfo.contactPerson,
      email: accountInfo.baseInfo.accountAddress.email,
      contactNumber: accountInfo.baseInfo.contactNumber,
      website: accountInfo.baseInfo.accountAddress.website,
      addressLine1: accountInfo.baseInfo.accountAddress.addressLine1,
      addressLine2: accountInfo.baseInfo.accountAddress.addressLine2,
      city: accountInfo.baseInfo.accountAddress.city,
      state: accountInfo.baseInfo.accountAddress.state,
      zip: accountInfo.baseInfo.accountAddress.zip,
      instruction: accountInfo.baseInfo.accountAddress.instruction,
      logo: accountInfo.baseInfo.logo || defaultProfileImage,
      status: accountInfo.baseInfo.status,
    };
  }

  public static buildAccountBusinessInfo(
    accounts: AccountBasicInfo[]
  ): BusinessInfo[] {
    return accounts.map((account) => ({
      id: account.accountId,
      name: account.accountName,
      contactPerson: account.contactPerson,
      contactNumber: account.contactNumber,
      logo: account.logo || defaultProfileImage,
      status:
        account.status === "active"
          ? ActiveStatus.Active
          : ActiveStatus.Inactive,
    }));
  }

  public static buildFleetsInfo(
    accountFleets: FleetInfo[]
  ): FleetForDatagrid[] {
    return accountFleets.map((accountFleet: FleetInfo) => ({
      id: accountFleet.accountFleetId,
      washTypeName: accountFleet.washTypeName,
      accountFleetId: accountFleet.accountFleetId,
      washTypeId: accountFleet.washTypeId,
      vehicleNumber: accountFleet.vehicleNumber,
      isPreSelected: accountFleet.isPreSelected,
    }));
  }

  public static buildChargeInformationRows(
    charges: ChargeInformation[]
  ): ChargeInformationRow[] {
    // TODO: implement sorting
    return charges
      .filter((item) => item.chargeType !== ChargeType.TripMinimum)
      .map((item) => ({
        id: item.washTypeId,
        washType: item.washTypeName,
        washRate: item.specialRate,
        frequency: item.washFrequency || "N/A",
        instructions: item.instructions || "N/A",
      }));
  }

  public static getTripMinimum(
    miscCharges: ChargeInformation[]
  ): ChargeInformation | undefined {
    return miscCharges.find(
      (item) => item.chargeType === ChargeType.TripMinimum
    );
  }

  public static getTripMinimumPrecise(tripMinimum: ChargeInformation) {
    return tripMinimum.specialRate.toFixed(2);
  }

  public static getMiscChargesForLocation(
    locationCharges: LocationCharge[],
    locationNumber: number
  ): ChargeInformation[] {
    const locationCharge = locationCharges.find(
      (e) => e.locationNumber === locationNumber
    );
    if (locationCharge) {
      return locationCharge.miscCharges;
    }

    return [];
  }

  public static getWashTypeChargesForLocation(
    locationCharges: LocationCharge[],
    locationNumber: number
  ): ChargeInformation[] {
    const locationCharge = locationCharges.find(
      (e) => e.locationNumber === locationNumber
    );
    if (locationCharge) {
      return locationCharge.washTypeCharges;
    }

    return [];
  }

  public static buildChargeInformationDropDown(
    charges: ChargeInformation[]
  ): DropdownItem[] {
    return charges.map((charge) => ({
      key: charge.washTypeName,
      value: charge.washTypeId,
    }));
  }

  public static buildAccountsDropdown(
    accounts: AccountBasicInfo[] | Account[]
  ): DropdownItem[] {
    return accounts.map((account) => ({
      key: account.accountName,
      value: account.accountId,
    }));
  }
}
