import { Fade, Grid2 as Grid, Paper, styled } from "@mui/material";
import {
  EditOrganizationProfileBody,
  EditOrganizationProfileHeader,
  ViewOrganizationProfileBody,
  ViewOrganizationProfileHeader,
} from "./fragments";
import { useEffect, useState } from "react";
import {
  useCreateOrganizationMutation,
  useFetchOrganizationLogoUploadPreSignedUrlQuery,
  useFetchOrganizationInfoQuery,
  useUpdateOrganizationMutation,
  useUploadFileToS3Mutation,
} from "../../api";
import { useAtom } from "jotai";
import {
  isCreateOrganizationModeAtom,
  selectedOrgIdAtom,
  toastOptionsAtom,
  isOrganizationProfileBodyEditModeAtom,
} from "../../atoms";
import { OrganizationProfileInfo } from "../../types";
import { OrganizationModel } from "../../models";
import { objectDiff } from "../../utils";
import { SadmOrganizationsRoute } from "../../routes";
import { useNavigate } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  ...theme.applyStyles("dark", {
    backgroundColor: "#1A2027",
  }),
  boxShadow: "none",
}));

export const OrganizationProfile = () => {
  const [isHeaderEditMode, setIsHeaderEditMode] = useState(false);
  const [isBodyEditMode, setIsBodyEditMode] = useAtom(
    isOrganizationProfileBodyEditModeAtom
  );
  const [logoName, setLogoName] = useState<string>();
  const [logoFile, setLogoFile] = useState<File>();
  const [logoUploadPreSignedUrl, setLogoUploadPreSignedUrl] =
    useState<string>();
  const [isCreateOrganizationMode, setIsCreateOrganizationMode] = useAtom(
    isCreateOrganizationModeAtom
  );
  const [selectedOrgId] = useAtom(selectedOrgIdAtom);
  const [profileInfo, setProfileInfo] = useState<OrganizationProfileInfo>();
  const [, setToastOptions] = useAtom(toastOptionsAtom);
  const fetchOrganizationInfoQuery = useFetchOrganizationInfoQuery(
    selectedOrgId,
    { enabled: !isCreateOrganizationMode }
  );

  const fetchOrganizationLogoUploadPreSignedUrlQuery =
    useFetchOrganizationLogoUploadPreSignedUrlQuery(
      selectedOrgId,
      logoName || "logo.png",
      { enabled: false }
    );
  const updateOrganizationMutation = useUpdateOrganizationMutation();
  const uploadFileToS3Mutation = useUploadFileToS3Mutation();
  const createOrganizationMutation = useCreateOrganizationMutation();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      (logoName && !isCreateOrganizationMode) ||
      (logoName && isCreateOrganizationMode && selectedOrgId !== 0)
    ) {
      fetchOrganizationLogoUploadPreSignedUrlQuery.refetch();
    }
  }, [logoName, selectedOrgId]);

  useEffect(() => {
    if (fetchOrganizationInfoQuery.isSuccess) {
      const profileInfo = OrganizationModel.buildOrganizationProfile(
        fetchOrganizationInfoQuery.data
      );
      setProfileInfo(profileInfo);
    }
  }, [
    fetchOrganizationInfoQuery.isSuccess,
    fetchOrganizationInfoQuery.isError,
    fetchOrganizationInfoQuery.data,
    fetchOrganizationInfoQuery.error,
    fetchOrganizationInfoQuery.isLoading,
  ]);

  useEffect(() => {
    setIsHeaderEditMode(isCreateOrganizationMode);
    if (isCreateOrganizationMode) setIsBodyEditMode(isCreateOrganizationMode);
    else setIsBodyEditMode(false);
  }, [isCreateOrganizationMode]);

  useEffect(() => {
    if (updateOrganizationMutation.isSuccess) {
      fetchOrganizationInfoQuery.refetch();

      if (isHeaderEditMode) setIsHeaderEditMode(false);
      if (isBodyEditMode) setIsBodyEditMode(false);

      setToastOptions({
        open: true,
        message: "Profile updated",
        severity: "success",
      });
    }

    if (updateOrganizationMutation.isError) {
      if (isHeaderEditMode) setIsHeaderEditMode(false);
      if (isBodyEditMode) setIsBodyEditMode(false);

      if (updateOrganizationMutation.error.status === 409) {
        setToastOptions({
          open: true,
          message: "Name already taken",
          severity: "error",
        });
      } else {
        setToastOptions({
          open: true,
          message: "Something went wrong",
          severity: "error",
        });
      }
    }
  }, [
    updateOrganizationMutation.isSuccess,
    updateOrganizationMutation.isError,
    updateOrganizationMutation.isLoading,
    updateOrganizationMutation.data,
    updateOrganizationMutation.error,
  ]);

  useEffect(() => {
    if (
      fetchOrganizationLogoUploadPreSignedUrlQuery.isSuccess ||
      fetchOrganizationLogoUploadPreSignedUrlQuery.isFetched
    ) {
      const preSignedUrl = fetchOrganizationLogoUploadPreSignedUrlQuery.data;

      setLogoUploadPreSignedUrl(preSignedUrl);
    }

    if (fetchOrganizationLogoUploadPreSignedUrlQuery.isError) {
      setToastOptions({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    }
  }, [
    fetchOrganizationLogoUploadPreSignedUrlQuery.isSuccess,
    fetchOrganizationLogoUploadPreSignedUrlQuery.isError,
    fetchOrganizationLogoUploadPreSignedUrlQuery.isLoading,
    fetchOrganizationLogoUploadPreSignedUrlQuery.data,
    fetchOrganizationLogoUploadPreSignedUrlQuery.error,
    fetchOrganizationLogoUploadPreSignedUrlQuery.isFetched,
    fetchOrganizationLogoUploadPreSignedUrlQuery.isFetching,
  ]);

  useEffect(() => {
    if (uploadFileToS3Mutation.isError) {
      setToastOptions({
        open: true,
        message: "Error while uploading logo",
        severity: "error",
      });
    }
  }, [
    uploadFileToS3Mutation.isSuccess,
    uploadFileToS3Mutation.isError,
    uploadFileToS3Mutation.isLoading,
    uploadFileToS3Mutation.data,
    uploadFileToS3Mutation.error,
  ]);

  useEffect(() => {
    if (createOrganizationMutation.isSuccess) {
      const orgId = createOrganizationMutation.data;
      setIsCreateOrganizationMode(false);
      setIsBodyEditMode(true);
      navigate(`${SadmOrganizationsRoute.path}/${orgId.toString()}`);
      setToastOptions({
        open: true,
        message: "Organization created",
        severity: "success",
      });
    }

    if (createOrganizationMutation.isError) {
      if (createOrganizationMutation.error.status === 409) {
        setToastOptions({
          open: true,
          message: "Name already taken",
          severity: "error",
        });
      } else {
        setToastOptions({
          open: true,
          message: "Something went wrong",
          severity: "error",
        });
      }
    }
  }, [
    createOrganizationMutation.isSuccess,
    createOrganizationMutation.isError,
    createOrganizationMutation.isLoading,
    createOrganizationMutation.data,
    createOrganizationMutation.error,
  ]);

  useEffect(() => {
    if (logoUploadPreSignedUrl && logoFile) {
      uploadFileToS3Mutation.mutate({
        file: logoFile,
        signedUrl: logoUploadPreSignedUrl,
      });
    }
  }, [logoUploadPreSignedUrl]);

  const handleEditHeader = ({
    data,
    logo,
  }: {
    data: Pick<OrganizationProfileInfo, "name" | "logo">;
    logo?: File;
  }) => {
    const changedProps = objectDiff<OrganizationProfileInfo>(profileInfo, data);

    if (logo && changedProps.logo) {
      setLogoName(changedProps.logo);
      setLogoFile(logo);
    }

    if (Object.keys(changedProps).length !== 0) {
      if (isCreateOrganizationMode && changedProps.name) {
        createOrganizationMutation.mutate({
          orgId: selectedOrgId,
          organizationInfo: {
            name: changedProps.name,
            logo: changedProps.logo,
          },
        });
      } else {
        updateOrganizationMutation.mutate({
          orgId: selectedOrgId,
          ...changedProps,
        });
        setIsHeaderEditMode(false);
      }
    } else {
      setIsHeaderEditMode(false);
      setToastOptions({
        open: true,
        message: "No changes detected",
        severity: "error",
      });
    }
  };

  const handleEditBody = (
    data: Pick<
      OrganizationProfileInfo,
      | "contactPerson"
      | "contactNumber"
      | "timezoneId"
      | "officeNumber"
      | "mobileNumber"
    >
  ) => {
    const changedProps = objectDiff(profileInfo, data);

    if (Object.keys(changedProps).length !== 0) {
      updateOrganizationMutation.mutate({
        orgId: selectedOrgId,
        ...changedProps,
      });
      setIsBodyEditMode(false);
    } else {
      setIsBodyEditMode(false);
      setToastOptions({
        open: true,
        message: "No changes detected",
        severity: "error",
      });
    }
  };

  return (
    <Grid
      container
      direction={"column"}
      spacing={3}
      sx={{
        width: "100%",
        height: "100%",
        direction: "row",
        borderRadius: "10px",
      }}
    >
      <Grid
        container
        size={12}
        direction={"row"}
        sx={{
          flexGrow: 1,
          boxShadow: "0px 0px 4px 0px #00000040",
          borderRadius: "10px",
        }}
      >
        <Grid size={12}>
          {(isHeaderEditMode || isCreateOrganizationMode) && (
            <Fade
              timeout={350}
              in={isHeaderEditMode || isCreateOrganizationMode}
              style={{
                height: "100%",
              }}
            >
              <div>
                <EditOrganizationProfileHeader
                  defaultValues={
                    !isCreateOrganizationMode ? profileInfo : undefined
                  }
                  handleEdit={handleEditHeader}
                  handleDiscard={() => {
                    if (!isCreateOrganizationMode) setIsHeaderEditMode(false);
                  }}
                  loading={createOrganizationMutation.isLoading}
                />
              </div>
            </Fade>
          )}

          {!isHeaderEditMode && (
            <Fade
              timeout={350}
              in={!isHeaderEditMode}
              style={{
                height: "100%",
              }}
            >
              <div>
                <ViewOrganizationProfileHeader
                  isLoading={
                    (!isCreateOrganizationMode &&
                      (fetchOrganizationInfoQuery.isLoading ||
                        fetchOrganizationInfoQuery.isFetching ||
                        uploadFileToS3Mutation.isLoading ||
                        !profileInfo)) ||
                    updateOrganizationMutation.isLoading
                  }
                  data={profileInfo}
                  handleEditMode={setIsHeaderEditMode}
                  handleUploadProfilePicture={handleEditHeader}
                />
              </div>
            </Fade>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        size={12}
        direction={"row"}
        sx={{
          flexGrow: 8,
          boxShadow: "0px 0px 4px 0px #00000040",
          borderRadius: "10px",
        }}
      >
        <Grid size={12} sx={{ minHeight: "250px" }}>
          <Item sx={{ height: "100%", borderRadius: "10px" }}>
            {isBodyEditMode && (
              <Fade
                timeout={350}
                in={isBodyEditMode}
                style={{
                  height: "100%",
                }}
              >
                <div>
                  <EditOrganizationProfileBody
                    defaultValues={
                      !isCreateOrganizationMode ? profileInfo : undefined
                    }
                    handleEdit={handleEditBody}
                    handleDiscard={() => {
                      setIsBodyEditMode(false);
                    }}
                  />
                </div>
              </Fade>
            )}
            {!isBodyEditMode && (
              <Fade
                timeout={350}
                in={!isBodyEditMode}
                style={{
                  height: "100%",
                }}
              >
                <div>
                  <ViewOrganizationProfileBody
                    isLoading={
                      (!isCreateOrganizationMode &&
                        (fetchOrganizationInfoQuery.isLoading ||
                          fetchOrganizationInfoQuery.isFetching ||
                          uploadFileToS3Mutation.isLoading ||
                          !profileInfo)) ||
                      updateOrganizationMutation.isLoading
                    }
                    data={profileInfo}
                    handleEditMode={setIsBodyEditMode}
                  />
                </div>
              </Fade>
            )}
          </Item>
        </Grid>
      </Grid>
    </Grid>
  );
};
