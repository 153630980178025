import { Box, Grid2 as Grid } from "@mui/material";
import { Button, SelectBox, TextField } from "../../common";
import { useAtom } from "jotai";
import { isCreateContactComponentActiveAtom } from "../../../atoms";
import { AccountContact, DropdownItem } from "../../../types";
import { useValidateForm } from "../../../hooks";
import { createContactSchema } from "../../../validations";
import { useEffect, useState } from "react";

const dropdown: DropdownItem[] = [
  { key: "Enabled", value: "true" },
  { key: "Disabled", value: "false" },
];

type CreateContactProps = {
  defaultValues?: AccountContact;
  handleCreate: (contact: any) => void;
  handleDiscard?: () => void;
};

export const CreateContact = ({
  defaultValues,
  handleCreate,
  handleDiscard,
}: CreateContactProps) => {
  const [hasChanges, setHasChanges] = useState(false);
  const [, setContactCreateComponentActive] = useAtom(
    isCreateContactComponentActiveAtom
  );
  const { register, handleSubmit, errors, reset, setValue } =
    useValidateForm(createContactSchema);

  const handleClickDiscard = () => {
    reset();
    setHasChanges(false);
    setContactCreateComponentActive(false);
  };

  const handleChange = () => {
    setHasChanges(true);
  };

  const handleCreateWrapper = (contact: any) => {
    reset();
    handleCreate(contact);
  };

  useEffect(() => {
    return () => {
      reset();
      setValue("isEmailRequired", "false");
      setValue("isSmsRequired", "false");
      setValue("isLoginRequired", "false");
    };
  }, []);

  return (
    <Box
      onSubmit={handleSubmit(handleCreateWrapper)}
      component={"form"}
      noValidate
      sx={{
        height: "100%",
      }}
    >
      <Grid
        container
        size={12}
        direction={"row"}
        spacing={3}
        sx={{
          boxShadow: "0px 0px 4px 0px #00000040",
          borderRadius: "10px",
          p: 4,
          mb: 2,
          width: "100%",
        }}
      >
        <Grid container size={{ md: 9, lg: 10, xs: 12 }} gap={3}>
          <Grid container size={12} spacing={3}>
            <Grid size={{ xs: 12, sm: 4, md: 3 }}>
              <TextField
                label="Title"
                name="title"
                register={register}
                error={errors["title"]}
                defaultValue={defaultValues?.title}
                onChange={handleChange}
                maxLength={255}
                required
                helperTextSx={{
                  whiteSpace: "nowrap !important",
                }}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 8, md: 6 }}>
              <TextField
                label="Name"
                name="name"
                register={register}
                error={errors["name"]}
                defaultValue={defaultValues?.name}
                onChange={handleChange}
                maxLength={255}
                required
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 3 }}>
              <SelectBox
                label="Portal Access"
                name="isLoginRequired"
                dropdown={dropdown}
                register={register}
                error={errors["isLoginRequired"]}
                defaultValue={
                  defaultValues
                    ? defaultValues.isLoginRequired?.toString()
                    : "false"
                }
                onChange={handleChange}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 4.5, md: 3 }}>
              <TextField
                label="Email"
                name="email"
                register={register}
                error={errors["email"]}
                defaultValue={defaultValues && defaultValues.email}
                onChange={handleChange}
                maxLength={255}
                required
                helperTextSx={{ whiteSpace: "nowrap !important" }}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 4.5, md: 3 }}>
              <TextField
                type="number"
                label="Phone"
                name="number"
                register={register}
                error={errors["number"]}
                defaultValue={defaultValues && defaultValues.number}
                onChange={handleChange}
                allowScientificNotation={false}
                required
                maxLength={20}
              />
            </Grid>
            <Grid size={{ xs: 6, sm: 3 }}>
              <SelectBox
                label="SMS"
                name="isSmsRequired"
                dropdown={dropdown}
                register={register}
                error={errors["isSmsRequired"]}
                defaultValue={
                  defaultValues
                    ? defaultValues.isSmsRequired?.toString()
                    : "false"
                }
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid size={{ xs: 6, sm: 3 }}>
              <SelectBox
                label="Email"
                name="isEmailRequired"
                dropdown={dropdown}
                register={register}
                error={errors["isEmailRequired"]}
                defaultValue={
                  defaultValues
                    ? defaultValues.isEmailRequired.toString()
                    : "false"
                }
                onChange={handleChange}
                required
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          direction="column"
          gap={3}
          size={{ md: 3, lg: 2, xs: 12 }}
          sx={{ justifyContent: "flex-end" }}
        >
          <Grid size={12}>
            <Button
              variantType="secondary"
              label="Discard"
              sx={{
                padding: "0.5rem",
                width: "100%",
              }}
              onClick={() => {
                reset();
                setHasChanges(false);
                handleDiscard ? handleDiscard() : handleClickDiscard();
              }}
            />
          </Grid>
          <Grid size={12}>
            <Button
              type={hasChanges ? "submit" : undefined}
              disabled={!hasChanges}
              label="Save Contact"
              variantType={hasChanges ? "primary" : "secondary"}
              sx={{
                padding: "0.5rem",
                width: "100%",
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
