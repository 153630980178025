import { env } from "./env-loader";

export const config = {
  environment: process.env.NODE_ENV || "development",
  api: {
    baseUrl: env("REACT_APP_BASE_URL", "https://dev-api.sprayzapp.com"),
    authService: {
      login: "/auth-service/v2/login",
      logoff: "/auth-service/v2/logoff",
    },
    accountService: {
      accountLogoUploadSignedUrl:
        "account-service-new/v2/organizations/{orgId}/accounts/{accountId}/files/{fileName}/logo-upload-url",
      bulkDeleteAccountMiscCharges:
        "account-service-new/v2/organizations/{orgId}/accounts/{accountId}/locations/{locationNumber}/miscellaneous-charges",
      bulkDeleteAccountWashCharges:
        "account-service-new/v2/organizations/{orgId}/accounts/{accountId}/locations/{locationNumber}/wash-charges",
      createAccount: "account-service-new/v2/organizations/{orgId}/accounts",
      createAccountContact:
        "account-service-new/v2/organizations/{orgId}/accounts/{accountId}/contacts",
      createAccountFinancial:
        "account-service-new/v2/organizations/{orgId}/accounts/{accountId}/financial",
      createAccountFleet:
        "account-service-new/v2/organizations/{orgId}/accounts/{accountId}/fleets",
      createAccountLocation:
        "account-service-new/v2/organizations/{orgId}/accounts/{accountId}/locations",
      createAccountMiscCharge:
        "account-service-new/v2/organizations/{orgId}/accounts/{accountId}/locations/{locationNumber}/miscellaneous-charges",
      createWashTypeSplInstruction:
        "account-service-new/v2/organizations/{orgId}/accounts/{accountId}/locations/{locationNumber}/wash-charge-special-instruction",
      deleteAccount:
        "account-service-new/v2/organizations/{orgId}/accounts/{accountId}",
      deleteAccountLocation:
        "account-service-new/v2/organizations/{orgId}/accounts/{accountId}/locations/{locationNumber}",
      deleteAccountMiscCharge:
        "account-service-new/v2/organizations/{orgId}/accounts/{accountId}/locations/{locationNumber}/miscellaneous-charges/{washTypeId}",
      deleteAccountWashCharge:
        "account-service-new/v2/organizations/{orgId}/accounts/{accountId}/locations/{locationNumber}/wash-charges/{washTypeId}",
      deleteAccountFleet:
        "account-service-new/v2/organizations/{orgId}/accounts/{accountId}/fleets",
      deleteContact:
        "account-service-new/v2/organizations/{orgId}/accounts/{accountId}/contacts/{contactId}",
      getAccountInfo:
        "account-service-new/v2/organizations/{orgId}/accounts/{accountId}/info",
      getAccountFleets:
        "account-service-new/v2/organizations/{orgId}/accounts/{accountId}/fleets",
      getUnWashedAccountFleets:
        "account-service-new/v2/organizations/{orgId}/accounts/{accountId}/list/fleet",
      getAccountLocations:
        "account-service-new/v2/organizations/{orgId}/accounts/{accountId}/locations",
      getAccountWashMiscCharges:
        "account-service-new/v2/organizations/{orgId}/accounts/{accountId}/locations/{locationNumber}/wash-misc-charges",
      getAllAccounts: "account-service-new/v2/organizations/{orgId}/accounts",
      getAllAccountsSummary:
        "account-service-new/v2/organizations/{orgId}/accounts/summary",
      updateAccountFinancial:
        "account-service-new/v2/organizations/{orgId}/accounts/{accountId}/financial",
      updateAccountFleet:
        "account-service-new/v2/organizations/{orgId}/accounts/{accountId}/fleets/{accountFleetId}",
      updateAccountLocation:
        "account-service-new/v2/organizations/{orgId}/accounts/{accountId}/locations/{locationNumber}",
      updateAccountMiscCharge:
        "account-service-new/v2/organizations/{orgId}/accounts/{accountId}/locations/{locationNumber}/miscellaneous-charges/{washTypeId}",
      updateAccountWashCharge:
        "account-service-new/v2/organizations/{orgId}/accounts/{accountId}/locations/{locationNumber}/wash-charges/{washTypeId}",
      updateContact:
        "account-service-new/v2/organizations/{orgId}/accounts/{accountId}/contacts/{contactId}",
      updateProfileAndAddress:
        "account-service-new/v2/organizations/{orgId}/accounts/{accountId}/profile-address",
      updateTripMinimum:
        "account-service-new/v2/organizations/{orgId}/accounts/{accountId}/locations/{locationNumber}/trip-minimum",
      uploadFleet:
        "account-service-new/v2/organizations/{orgId}/accounts/{accountId}/locations/{locationNumber}/fleet-upload-url",
      uploadStatus:
        "account-service-new/v2/organizations/{orgId}/accounts/{accountId}/locations/{locationNumber}/fleet-upload-status",
    },
    quickbooksIntegrationService: {
      attachPdfToQuickbooks:
        "quickbooks-integration-service/v2/organizations/{orgId}/accounts/{accountId}/invoices/{invoiceNumber}/attach-file",
      createOrUpdateQBCreds:
        "quickbooks-integration-service/v2/organizations/{orgId}/quickbooks",
      getQBAuthUri:
        "quickbooks-integration-service/v2/organizations/{orgId}/qb-auth-uri",
      getQBCredentials:
        "quickbooks-integration-service/v2/organizations/{orgId}/quickbooks",
      sendInvoiceToQuickbooks:
        "quickbooks-integration-service/v2/organizations/{orgId}/accounts/{accountId}/invoices/{invoiceNumber}/to-quickbooks",
    },
    invoiceService: {
      bulkUpdateInvoiceStatus:
        "invoice-service/v2/organizations/{orgId}/accounts/{accountId}/invoices/update-status",
      createInvoiceNote:
        "invoice-service/v2/organizations/{orgId}/accounts/{accountId}/invoices/{invoiceNumber}/notes",
      regenerateInvoice:
        "invoice-service/v2/organizations/{orgId}/accounts/{accountId}/invoices/{invoiceNumber}/regenerate",
      downloadInvoicesCsv:
        "invoice-service/v2/organizations/{orgId}/accounts/{accountId}/download-invoices-csv",
      getPaginatedInvoices:
        "invoice-service/v2/organizations/{orgId}/accounts/{accountId}/invoices",
      getInvoiceGenerationStatus:
        "invoice-service/v2/organizations/{orgId}/accounts/{accountId}/invoices-generation-status?requestId={requestId}",
      getInvoiceNotes:
        "invoice-service/v2/organizations/{orgId}/accounts/{accountId}/invoices/{invoiceNumber}/notes",
      getInvoiceOverview:
        "invoice-service/v2/organizations/{orgId}/accounts/{accountId}/invoices/{invoiceNumber}",
      previewInvoice:
        "invoice-service/v2/organizations/{orgId}/accounts/{accountId}/invoices/{invoiceNumber}/preview",
      createOnDemandInvoice:
        "invoice-service/v2/organizations/{orgId}/accounts/{accountId}/invoice-ondemand",
    },
    organizationService: {
      createOrganisation: "organisation-service/v2/organizations",
      deleteOrganisation: "organisation-service/v2/organizations/{orgId}",
      getAllOrganizations: "organisation-service/v2/organizations",
      getOrganisation: "organisation-service/v2/organizations/{orgId}",
      getOrgLogoUploadSignedUrl:
        "organisation-service/v2/organizations/{orgId}/files/{fileName}/logo-upload-url",
      updateOrganisation: "organisation-service/v2/organizations/{orgId}",
      updateOrganisationAddress:
        "organisation-service/v2/organizations/{orgId}/address",
    },
    reportService: {
      getSuperAdminDashboard:
        "report-service/v2/organizations/{orgId}/dashboard",
      getUserDashboard:
        "report-service/v2/organizations/{orgId}/accounts/{accountId}/dashboard",
      getAccountActivityReport:
        "report-service/v2/organizations/{orgId}/accounts/{accountId}/startDate/{startDate}/endDate/{endDate}",
      getWashDetailsReport:
        "report-service/v2/organizations/{orgId}/accounts/{accountId}/wash-details",
      getWashDetailsPdfReport:
        "report-service/v2/organizations/{orgId}/accounts/{accountId}/startDate/{startDate}/endDate/{endDate}/washDetails",
      getLeadReport:
        "report-service/v2/organizations/{orgId}/accounts/{accountId}/startDate/{startDate}/endDate/{endDate}/lead",
    },
    userService: {
      getAllLeads: "user-service/v2/organizations/{orgId}/leads",
      getUser: "user-service/v2/organizations/{orgId}/users/{userId}",
      getAllUsers: "user-service/v2/organizations/{orgId}/users",
      createUser: "user-service/v2/organizations/{orgId}/user",
      deleteUser: "user-service/v2/organizations/{orgId}/users/{userId}",
      deleteUsers: "user-service/v2/organizations/{orgId}/users",
      updateUser: "user-service/v2/organizations/{orgId}/users/{userId}",
      adminResetPassword:
        "user-service/v2/organizations/{orgId}/users/{userId}/reset-password",
      uploadProfilePic:
        "user-service/v2/organizations/{orgId}/users/{userId}/files/{fileName}/profile-pic-upload-url",
      updateUserProfilePic:
        "user-service/v2/organizations/{orgId}/users/{userId}/profile-pic",
      changePassword:
        "user-service/v2/organizations/{orgId}/users/{id}/change-password",
      forgotPassword: "user-service/v2/forgot-password",
      validateResetPasswordToken:
        "user-service/v2/is-password-reset-token-valid",
      resetPassword:
        "user-service/v2/organizations/{orgId}/users/{id}/change-password-using-token",
    },
    washInventoryService: {
      getAllWashes:
        "wash-inventory-service/v2/organizations/{orgId}/accounts/{accountId}/washes",
      getAllWashInventory:
        "wash-inventory-service/v2/organizations/{orgId}/wash-inventories/accounts/{accountId}/locations/0/leads/0/startTime/{startDate}/endTime/{endDate}",
      getAllUnbilledWashInventory:
        "wash-inventory-service/v2/organizations/{orgId}/unbilled-wash-inventories/accounts/{accountId}/locations/0/startTime/{startDate}/endTime/{endDate}",
      downloadWashesCsv:
        "wash-inventory-service/v2/organizations/{orgId}/accounts/{accountId}/download-washes-csv",
      updateWashInventory:
        "wash-inventory-service/v2/organizations/{orgId}/accounts/{accountId}/locations/{locationNumber}/wash-inventories/{id}",
      deleteWashInventory:
        "wash-inventory-service/v2/organizations/{orgId}/wash-inventories/{id}",
      deleteWashInventories:
        "wash-inventory-service/v2/organizations/{orgId}/wash-inventories",
      bulkUpdateWashInventory:
        "wash-inventory-service/v2/organizations/{orgId}/accounts/{accountId}/wash-inventories",
      createWashInventory:
        "wash-inventory-service/v2/organizations/{orgId}/accounts/{accountId}/locations/{locationNumber}/wash-inventory",
    },
    washTypeService: {
      getAllMiscellaneousWashTypes:
        "wash-type-service/v2/organizations/{orgId}/miscellaneous-wash-types",
      getAllWashTypes: "wash-type-service/v2/organizations/{orgId}/wash-types",
      createWashType: "wash-type-service/v2/organizations/{orgId}/wash-type",
      updateWashType:
        "wash-type-service/v2/organizations/{orgId}/wash-types/{id}",
      deleteWashType:
        "wash-type-service/v2/organizations/{orgId}/wash-types/{id}",
      deleteWashTypes: "wash-type-service/v2/organizations/{orgId}/wash-types",
    },
    worldpayIntegrationService: {
      initiateWorldpayTransaction:
        "worldpay-integration-service/v2/organizations/{orgId}/accounts/{accountId}/invoices/{invoiceNumber}/initiate-payment",
    },
  },
  images: {
    logo: {
      baseUrl: "https://sprayzapp-images.s3.us-west-2.amazonaws.com/",
      default: "1/409/default.png",
    },
  },
};
