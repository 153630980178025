import { Box, Checkbox, FormControlLabel, Grid2 as Grid } from "@mui/material";
import { DropdownItem, ModalForm, WashType } from "../../../types";
import { SelectBox, TextField } from "../../common";
import { OrganizationModel } from "../../../models";
import { selectedOrgIdAtom, userAtom } from "../../../atoms";
import { useAtom } from "jotai";
import { Role } from "../../../enums";
import { useFetchOrganizationsQuery } from "../../../api";
import { useEffect, useState } from "react";
import { createWashTypeBodySchema } from "../../../validations";
import { useValidateForm } from "../../../hooks";

type FormData = Omit<WashType, "miscellaneous">;

type WashTypeFormProps = ModalForm<FormData, FormData> & {
  isCreateMode?: boolean;
  isMiscellaneous?: boolean;
};

export const WashTypeForm = ({
  id,
  isCreateMode,
  isMiscellaneous,
  defaultValues,
  handleEdit,
}: WashTypeFormProps) => {
  const [user] = useAtom(userAtom);
  const [selectedOrgId] = useAtom(selectedOrgIdAtom);
  const { register, handleSubmit, errors } = useValidateForm(
    createWashTypeBodySchema
  );
  const [organizationsDropdown, setOrganizationsDropdown] = useState<
    DropdownItem[]
  >([]);
  const unitOfMeasurementDropDown: DropdownItem[] = [
    {
      key: "Quantity",
      value: "quantity",
    },
    {
      key: "Hours",
      value: "hours",
    },
  ];
  const fetchOrganizationsQuery = useFetchOrganizationsQuery({
    enabled: user?.roleCode === Role.SuperAdmin,
  });

  useEffect(() => {
    if (fetchOrganizationsQuery.isSuccess) {
      const dropdown = OrganizationModel.buildOrganizationDropdown(
        fetchOrganizationsQuery.data
      );
      setOrganizationsDropdown(dropdown);
    } else if (fetchOrganizationsQuery.isError) {
      setOrganizationsDropdown([]);
    }
  }, [
    fetchOrganizationsQuery.isSuccess,
    fetchOrganizationsQuery.isError,
    fetchOrganizationsQuery.data,
    fetchOrganizationsQuery.error,
    fetchOrganizationsQuery.isLoading,
  ]);

  const handleEditWrapper = (
    data: Omit<WashType, "id" | "miscellaneous"> & { isTripMinimum?: boolean }
  ) => {
    handleEdit({
      ...data,
      id: defaultValues ? defaultValues.id : 0,
    });
  };

  return (
    <Box
      onSubmit={handleSubmit(handleEditWrapper)}
      component={"form"}
      id={id}
      noValidate
      sx={{
        height: "100%",
      }}
    >
      <Grid
        container
        size={12}
        direction={"column"}
        gap={2}
        sx={{
          borderRadius: "10px",
          p: 3,
          mb: 2,
          width: "100%",
        }}
      >
        <Grid size={12}>
          <TextField
            label="Name"
            name="name"
            register={register}
            error={errors["name"]}
            defaultValue={defaultValues && defaultValues.name}
            maxLength={100}
          />
        </Grid>
        <Grid size={12}>
          <TextField
            label="Description"
            name="description"
            register={register}
            error={errors["description"]}
            defaultValue={defaultValues && defaultValues.description}
            maxLength={300}
          />
        </Grid>

        <Grid size={12}>
          <SelectBox
            label="Unit Of Measurement"
            name="unitOfMeasurement"
            register={register}
            error={errors["unitOfMeasurement"]}
            dropdown={unitOfMeasurementDropDown}
            defaultValue={defaultValues && defaultValues.unitOfMeasurement}
          />
        </Grid>

        {user?.roleCode === Role.SuperAdmin && (
          <Grid size={12}>
            <SelectBox
              label="Organization"
              name="orgId"
              register={register}
              error={errors["orgId"]}
              dropdown={organizationsDropdown}
              disabled={!isCreateMode}
              valueAsNumber={true}
              defaultValue={defaultValues && defaultValues.orgId}
            />
          </Grid>
        )}
        {!isCreateMode && (
          <input
            type="number"
            style={{ display: "none" }}
            {...register("orgId")}
            value={defaultValues?.orgId}
          />
        )}
        {isCreateMode && user?.roleCode !== Role.SuperAdmin && (
          <input
            type="number"
            style={{ display: "none" }}
            {...register("orgId")}
            value={selectedOrgId}
          />
        )}
        <Grid size={12}>
          <TextField
            label="Code"
            name="code"
            register={register}
            error={errors["code"]}
            defaultValue={defaultValues && defaultValues.code}
            maxLength={15}
          />
        </Grid>
        <Grid size={12}>
          <TextField
            type={"number"}
            label="Rate"
            name="rate"
            register={register}
            error={errors["rate"]}
            defaultValue={defaultValues && defaultValues.rate}
          />
        </Grid>
        <Grid size={12}>
          <TextField
            type={"number"}
            label="Quickbooks Reference ID"
            name="quickbookReferenceId"
            register={register}
            error={errors["quickbookReferenceId"]}
            defaultValue={defaultValues && defaultValues.quickbookReferenceId}
          />
        </Grid>
        {isMiscellaneous && (
          <Grid size={12}>
            <FormControlLabel
              control={
                <Checkbox {...(register && register("isTripMinimum"))} />
              }
              label={"Trip Minimum"}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
