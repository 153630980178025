import { useEffect, useState } from "react";
import { Modal } from "./Modal";
import { CropContainerV2 } from "../crop-container";

type ImageCropModalProps = {
  open: boolean;
  title?: string;
  file: File;
  onClose: () => void;
  onConfirm: (file: File) => void;
};

export const ImageCropModal = ({
  open,
  title = "Crop",
  file,
  onClose,
  onConfirm,
}: ImageCropModalProps) => {
  const [imageSrc, setImageSrc] = useState<string>("");
  const [croppedAreaPixels, setCroppedAreaPixels] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });
  const onCropComplete = (croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const downloadCroppedFile = (file: File) => {
    const url = URL.createObjectURL(file);
    const link = document.createElement("a");

    // Set the download attribute to specify the file name
    link.href = url;
    link.download = file.name;

    // Programmatically trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up and revoke the object URL
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const cropImage = async (
    imageFile: File,
    x: number,
    y: number,
    width: number,
    height: number
  ): Promise<File> => {
    return new Promise<File>((resolve, reject) => {
      const img = new Image();
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      if (!ctx) {
        reject(new Error("Could not create canvas context"));
        return;
      }

      img.src = URL.createObjectURL(imageFile);

      img.onload = () => {
        canvas.width = width;
        canvas.height = height;

        // Draw the cropped area onto the canvas
        ctx.drawImage(img, x, y, width, height, 0, 0, width, height);

        canvas.toBlob((blob) => {
          if (blob) {
            const croppedFile = new File([blob], imageFile.name, {
              type: imageFile.type,
              lastModified: Date.now(),
            });
            resolve(croppedFile);
          } else {
            reject(new Error("Failed to crop image"));
          }
        }, imageFile.type);
      };

      img.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleConfirm = async () => {
    try {
      const croppedFile = await cropImage(
        file,
        croppedAreaPixels.x,
        croppedAreaPixels.y,
        croppedAreaPixels.width,
        croppedAreaPixels.height
      );
      onConfirm(croppedFile);
    } catch (error) {
      console.error("Error cropping image:", error);
    }
    // fetchLogoUploadPresignedUrlQuery.refetch();
  };

  useEffect(() => {
    const imageSrc = URL.createObjectURL(file);
    setImageSrc(imageSrc);
  }, [file]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={title}
      content={
        imageSrc && (
          <CropContainerV2 image={imageSrc} onCropComplete={onCropComplete} />
        )
      }
      primaryButtonText="Confirm"
      secondaryButtonText="Cancel"
      onPrimaryButtonClick={handleConfirm}
      onSecondaryButtonClick={onClose}
      sx={{
        modal: { width: { lg: "40%", xs: "80%", sm: "50%" }, p: 1 },
      }}
    />
  );
};
